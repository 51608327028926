import SystemTextMessage from './SystemTextMessage';
import { showShowBudgetModal } from 'components/molecules/Modals/ShowBudgetModal';
import { useAuth } from 'contexts/Authentication';
import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';
import { ServiceBudget } from 'services/apiServices/IProTubeApi/schemas/ServiceBudget.schema';
import {
  acceptServiceBudget,
  denyServiceBudget,
} from 'services/apiServices/IProTubeApi/serviceBudgets';
import { showHandledErrors, showMessageStatus } from 'utils/SwalUtils';

export interface ServiceBudgetActionMessageProps {
  message: ChatMessage;
}
const ServiceBudgetActionMessage = ({
  message,
}: ServiceBudgetActionMessageProps) => {
  const { state } = useAuth();
  const serviceBudget = message.serviceBudget as ServiceBudget;

  const clickHereButton = (
    <button
      className="font-bold"
      onClick={() => {
        showShowBudgetModal({
          serviceBudgetId: message.src,
          onAccept:
            !!state.isClient &&
            (serviceBudget => {
              acceptServiceBudget(message.src)
                .then(() => {
                  showMessageStatus({
                    imageType: 'success',
                    titleContent: 'Orçamento foi aceito!',
                    textContent: `O Nober será notificado.`,
                    customClass: 'font-poppins',
                  });
                })
                .catch(err => {
                  showHandledErrors(err, {
                    showUnhandledModal: true,
                  }).catch(() => {
                    //
                  });
                });
            }),
          onDeny:
            !!state.isClient &&
            (serviceBudget => {
              denyServiceBudget(message.src)
                .then(() => {
                  showMessageStatus({
                    imageType: 'success',
                    titleContent: 'Orçamento negado!',
                    textContent: `O Nober será notificado.`,
                    customClass: 'font-poppins',
                  });
                })
                .catch(err => {
                  showHandledErrors(err, {
                    showUnhandledModal: true,
                  }).catch(() => {
                    //
                  });
                });
            }),
        });
      }}
    >
      aqui
    </button>
  );

  return (
    <SystemTextMessage>
      <span
        className={`w-full px-4 text-sm py-2 rounded-lg inline-block font-semibold text-left`}
      >
        Clique {clickHereButton} para ver o orçamento n°{' '}
        {serviceBudget?.code || ''}
      </span>

      {state.isClient && serviceBudget?.status === 'PENDING' && (
        <div className="flex w-full items-center px-4 py-2">
          <AiFillWarning />
          <span
            className={`w-full pl-2 text-sm rounded-lg inline-block font-semibold text-left`}
          >
            O Nober está esperando sua resposta !
          </span>
        </div>
      )}
    </SystemTextMessage>
  );
};

export default ServiceBudgetActionMessage;

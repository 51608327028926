import api from '../iProTubeApi';
import { AccountBalanceResponse } from './responses/AccountBalance.response';
import { AccountStatusResponse } from './responses/AccountStatus.response';
import { SetupCreditCard } from './responses/SetupCreditCard.response';
import {
  GetTeacherOnboardingUrlQueryParams,
  ITeacherOnboarding,
  PatchUserCreditCardDTO,
} from './types';

export async function getTeacherOnboardingUrl(
  uid: string,
  options?: GetTeacherOnboardingUrlQueryParams,
): Promise<ITeacherOnboarding> {
  return await api
    .get(`/stripe/onboarding/${uid}`, {
      params: {
        returnPath: options?.returnPath,
        refreshPath: options?.refreshPath,
      },
    })
    .then(({ data }) => data);
}

export async function getCardSetupClientSecret(
  userId: string,
): Promise<SetupCreditCard> {
  return await api
    .get(`/stripe/credit-cards/${userId}`)
    .then(({ data }) => data);
}

export async function getAccountStatus(
  stripeId: string,
): Promise<AccountStatusResponse> {
  return await api
    .get(`/stripe/user/${stripeId}/status`)
    .then(({ data }) => data);
}

export async function getAccountBalance(
  stripeId: string,
): Promise<AccountBalanceResponse> {
  return await api
    .get(`/stripe/user/${stripeId}/balance`)
    .then(({ data }) => data);
}

export async function patchUserDefaultCreditCard(
  userId: string,
  dto: PatchUserCreditCardDTO,
): Promise<SetupCreditCard> {
  return await api
    .patch(`/stripe/credit-cards/${userId}`, dto)
    .then(({ data }) => data);
}

// after-onboard
// onboarding

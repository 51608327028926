import { useSidebarContext } from 'components/molecules/sidebars/Sidebar2/SidebarContext';
import { FiMenu } from 'react-icons/fi';
import React from 'react';
import TopbarNotifications from '../TopbarNotifications';
import { TOUR_IDS } from 'config/tour';

export type TopbarProps = {
  userThumbnailUrl?: string;
  userDisplayName?: string;
  userRole?: string;
  showSidebar?: boolean;
};
// Based on https://tailwindcomponents.com/component/responsive-sidebar-layout
const Topbar = ({
  userThumbnailUrl,
  userDisplayName,
  userRole,
  showSidebar = true,
}: TopbarProps) => {
  const { state, setIsOpen } = useSidebarContext();

  return (
    <header className="header bg-transparent  py-2 px-4">
      <div className="header-content flex items-center flex-row">
        {state.isMobile && !state.isOpen && showSidebar && (
          <div onClick={() => setIsOpen(true)} className="cursor-pointer">
            <FiMenu
              id={TOUR_IDS.NAV.open}
              size={25}
              className="text-titleColor"
            />
          </div>
        )}
        <div className="flex ml-auto w-full justify-end">
          <TopbarNotifications />
          <a className="flex flex-row items-center">
            <div className="flex flex-col items-center w-full justify-center">
              {userThumbnailUrl && (
                <img
                  src={userThumbnailUrl}
                  className="rounded-full h-10 w-10"
                />
              )}
              {userDisplayName && (
                <div className="hidden lg:flex flex-col ml-2">
                  <span className="truncate w-20 text-titleColor   font-semibold tracking-wide leading-none">
                    {userDisplayName}
                  </span>
                </div>
              )}
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Topbar;

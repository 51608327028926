import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactSwal,
  showHandledErrors,
  showMessageStatus,
} from 'utils/SwalUtils';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import StripeCreditCard from 'components/molecules/StripeCreditCard';
import { SetupCreditCard } from 'services/apiServices/IProTubeApi/stripe/responses/SetupCreditCard.response';
import {
  getCardSetupClientSecret,
  patchUserDefaultCreditCard,
} from 'services/apiServices/IProTubeApi/stripe';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'config/constants';
import { User } from 'services/apiServices/IProTubeApi/schemas/User.schema';
import { SweetAlertResult } from 'sweetalert2';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export type ClientCardsModalProps = {
  user: User;
  onFinish: (success: boolean) => void;
};
const ClientCardsContent = ({ user, onFinish }: ClientCardsModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [setupCreditCard, setSetupCreditCard] = useState<
    SetupCreditCard | undefined
  >();

  const canSubmit = useCallback(() => {
    return !(!stripe || isLoading);
  }, [isLoading, stripe]);

  useEffect(() => {
    if (!setupCreditCard && !!user._id) {
      getCardSetupClientSecret(user._id)
        .then(setSetupCreditCard)
        .catch(err => {
          showHandledErrors(err, {
            showUnhandledModal: true,
          }).catch(() => {
            //
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCreditCardSubmit = async event => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !setupCreditCard) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const result = await stripe.confirmCardSetup(
        setupCreditCard.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: user?.name,
              email: user?.email,
            },
          },
        },
      );
      if (result.error) {
        showMessageStatus({
          imageType: 'error',
          titleContent: 'Erro ao atualizar cartão!',
          textButton: 'Okay',
          textContent: result.error.message,
          customClass: 'font-poppins',
        });
      } else {
        await patchUserDefaultCreditCard(user?._id, {
          paymentMethod: result.setupIntent.payment_method,
        }).then(() => {
          showMessageStatus({
            imageType: 'success',
            titleContent: 'Cartão de crédito atualizado!',
            textButton: 'Okay',
            textContent: `Seu cartão de crédito foi atualizado com sucesso!.`,
            customClass: 'font-poppins',
          });

          onFinish(true);
        });
      }
    } catch (err) {
      showHandledErrors(err, {
        showUnhandledModal: true,
      }).catch(() => {
        //
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-coljustify-center flex-grow w-full font-poppins p-2">
      <div className="flex flex-col space-y-4">
        <div className="flex w-full justify-center items-center">
          <img src="/images/add-credit-card.svg" className="w-1/2 h-auto" />
        </div>
        <span className="text-xl font-semibold text-black uppercase">
          {!user?.canBeCharged ? 'Cadastrar cartão' : 'Mudar cartão'}
        </span>
        <p className="text-lg text-gray-400">
          Antes de seguir adiante você precisa cadastrar um cartão de crédito
          válido. Este cartão será utilizado para realizar pagamentos na
          plataforma.
        </p>
        <form onSubmit={handleCreditCardSubmit} className="bg-white">
          <div className="bg-white border border-primaryColor text-primaryColor py-4 px-2 mt-2 rounded-md w-full">
            <StripeCreditCard />
          </div>
          <div className="flex justify-end mt-8">
            <button
              type="submit"
              disabled={!canSubmit()}
              className={`flex items-center justify-center w-full py-3 space-x-4 text-white rounded-lg lg:w-1/3 font-poppins ${
                canSubmit() ? 'bg-primaryColor' : 'bg-primaryColor opacity-75'
              }`}
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ClientCardsModal = (props: ClientCardsModalProps) => (
  <Elements stripe={stripePromise}>
    <ClientCardsContent {...props} />
  </Elements>
);

export const showClientCardsModal = (
  props: ClientCardsModalProps,
): Promise<SweetAlertResult<any>> => {
  return ReactSwal.fire({
    html: <ClientCardsModal {...props} />,
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export default ClientCardsModal;

import { mensageTypes } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';

export const getTypeFromMimeType = (
  mimetype: string,
): typeof mensageTypes[number] => {
  if (mimetype.includes('audio')) {
    return 'audio';
  }
  if (mimetype.includes('video')) {
    return 'video';
  }
  if (mimetype.includes('image')) {
    return 'image';
  }
  return 'text';
};
export function getDirectMessagesRoomId(userId: string): string {
  return `${userId}-chat`;
}

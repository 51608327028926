import { format, differenceInDays } from 'date-fns';

export const dateToDateString = (date: Date | number): string => {
  return format(date, 'dd/MM/yyyy');
};

export const dateToDatetimeString = (date: Date | number): string => {
  return format(date, 'dd/MM/yyyy HH:mm');
};

export const dateToTimeString = (date: Date | number): string => {
  return format(date, 'HH:mm');
};

export const dateToTimeOrDateString = (date: Date | number): string => {
  if (differenceInDays(Date.now(), date) === 0) {
    return dateToTimeString(date);
  }
  return dateToDateString(date);
};

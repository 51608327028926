import { useAuth } from 'contexts/Authentication';
import { useFloatingChat } from 'contexts/FloatingChat';
import { useSocket } from 'contexts/Socket';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import useSound from 'use-sound';
import { getChatMessages } from 'services/apiServices/IProTubeApi/chatMessages';
import { getUserChats } from 'services/apiServices/IProTubeApi/chats';
import {
  PreparedChat,
  PreparedChatUser,
} from 'services/apiServices/IProTubeApi/schemas/Chat';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';

import { Logger } from 'services/logger';

interface AttendanceState {
  isConnected: boolean;
  chats: Chats;
  selectedChat?: PreparedChat;
  unreadMessages: number;
}
interface AttendanceContextData {
  attendanceState: AttendanceState;
  getUserFromChat: (chatId: string) => PreparedChatUser;
  loadChats: () => Promise<{
    chats: Chats;
  }>;
  setSelectedChat: (chatId: string) => Promise<void>;
  clear: () => Promise<void>;
}
export type Chats = { [key: string]: PreparedChat };

export interface IAddMessageToChat {
  chatId: string;
  messages: ChatMessage[];
  clearOldMessages?: boolean;
}
const AttendanceContext = createContext<AttendanceContextData>(
  {} as AttendanceContextData,
);
const logger = new Logger('AttendanceProvider');

export interface IAttendanceProviderProps {
  children: React.ReactNode;
}

const defaultState: AttendanceState = {
  chats: {},
  isConnected: false,
  unreadMessages: 0,
};
const AttendanceProvider = ({ children }: IAttendanceProviderProps) => {
  const [playSound] = useSound('/sounds/eq_beep.mp3');
  const [attendanceState, setAttendanceState] = useState<AttendanceState>(
    defaultState,
  );
  const { state, isUserAuthenticated } = useAuth();
  const { isSocketConnected, socketState, socketService } = useSocket();

  const { isOpen } = useFloatingChat();

  console.debug('AttendanceProvider-state', attendanceState);
  const getChatUnreadMessages = useCallback(
    (chat: PreparedChat) => {
      let unreadMessages = 0;
      if (chat.messages) {
        (chat.messages || []).forEach(message => {
          if (state.user?._id !== message.user && !message.wasRead) {
            unreadMessages += 1;
          }
        });
      } else {
        unreadMessages += chat.unreadMessages || 0;
      }

      return unreadMessages;
    },
    [state.user?._id],
  );
  const getUnreadMessages = useCallback(
    (chats: Chats): number => {
      if (!chats) return 0;

      let unreadMessages = 0;

      Object.values(chats).forEach(chat => {
        unreadMessages += getChatUnreadMessages(chat);
      });

      return unreadMessages;
    },
    [getChatUnreadMessages],
  );
  const getUserFromChat = useCallback(
    (chatId: string) => {
      const chat = attendanceState?.chats[chatId];
      return chat && chat.user;
    },
    [attendanceState],
  );

  const clear = () => {
    setAttendanceState(defaultState);
  };
  const loadChats = useCallback(async () => {
    if (!isUserAuthenticated())
      return {
        chats: {},
      };

    const userChats = await getUserChats({
      pagination: {
        limit: 9999,
        skip: 0,
      },
    });

    const chats_ = {};
    userChats.forEach(c => {
      chats_[c._id] = c;
    });

    let selectedChatState = attendanceState.selectedChat;

    if (attendanceState.selectedChat) {
      const newSelectedChat = userChats.find(
        o => o._id === attendanceState.selectedChat?._id,
      );
      logger.debug(
        `==> Selecionando automaticamente chat ${newSelectedChat?._id}`,
      );
      selectedChatState = Object.assign({}, newSelectedChat);
    }
    const unreadMessages = getUnreadMessages(chats_);
    setAttendanceState(oldState => {
      return {
        ...oldState,
        selectedChatState,
        chats: Object.assign({}, chats_),
        unreadMessages,
      };
    });

    return {
      chats: chats_,
      unreadMessages,
    };
  }, [attendanceState.selectedChat, getUnreadMessages, isUserAuthenticated]);

  // useEffect(() => {
  //   loadChats();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.isTeacher]);

  const setSelectedChat = useCallback(
    async (chatId: string | null): Promise<void> => {
      if (chatId === null) {
        console.debug('Removing selected chat');
        setAttendanceState(oldState => {
          return {
            ...oldState,
            selectedChat: undefined,
          };
        });

        return;
      }
      let chat = attendanceState.chats[chatId];
      // socketService?.markChatAsRead({
      //   chatId: id,
      //   userId: state?.user?._id,
      //   senderId: state?.user?._id,
      // });

      if (!chat) {
        console.debug('Chat not found, fetching all sites from backend');
        const userChats = await getUserChats({
          pagination: {
            limit: 9999,
            skip: 0,
          },
        });

        chat = userChats.find(({ _id }) => _id === chatId);

        console.debug('Chat fetched from backend', chat);
      }

      if (chat) {
        chat.messages = await getChatMessages({
          filters: {
            chat: chat._id,
          },
          pagination: {
            limit: 9999,
            skip: 0,
          },
          populates: {
            'populate-attendance': true,
            'populate-serviceBudget': true,
          },
        }).then(({ results }) => results);

        if (chat.unreadMessages > 0 && socketService) {
          socketService.markChatAsRead({
            chatId,
            senderId: state.user?._id as string,
            userId: state.user?._id as string,
            toId: chat.user?._id,
          });
        }
      }

      setAttendanceState(oldState => {
        return {
          ...oldState,
          selectedChat: chat,
        };
      });
    },
    [attendanceState.chats, socketService, state.user?._id],
  );
  const addMessageToChat = ({
    messages,
    chatId,
    clearOldMessages = false,
  }: IAddMessageToChat) => {
    setAttendanceState(oldState => {
      logger.debug(`==> Recebendo mensagem`);
      const newState = Object.assign({}, oldState);
      const newChats = Object.assign({}, newState.chats);

      if (!newChats[chatId]) {
        logger.debug(`==> Recebendo mensagem de um chat novo`);
        loadChats();
        return newState;
      }
      const lastMessage = messages[messages.length - 1];
      const newChat = Object.assign({}, newChats[chatId]);

      newChat.lastMessageAt = lastMessage.createdAt;
      newChat.lastMessage =
        lastMessage.type === 'text' ? lastMessage.src : lastMessage.type;

      const newMessages = clearOldMessages
        ? messages
        : [...(newChat?.messages || []), ...messages];

      const newSelectedChat = newState.selectedChat
        ? Object.assign({}, newState.selectedChat)
        : undefined;

      newChat.messages = newMessages;
      newChats[chatId] = newChat;
      newChats[chatId].unreadMessages = getChatUnreadMessages(newChats[chatId]);

      if (newChat._id === newSelectedChat?._id) {
        logger.debug(`==> Adicionando mensagem ao chat aberto`);

        newSelectedChat.messages = newMessages;
        newSelectedChat.nMessages = newMessages.length;
        newSelectedChat.unreadMessages = getChatUnreadMessages(
          newChats[chatId],
        );
      } else {
        logger.debug(
          `==> Adicionando mensagem a um chat fechado ${newChats.selectedChat?._id} x ${newChat._id}`,
        );
      }

      if (
        isOpen &&
        (newMessages[0].chat as string) === newState.selectedChat?._id &&
        newChats[chatId].unreadMessages > 0
      ) {
        if (socketService) {
          socketService.markChatAsRead({
            chatId: newState.selectedChat?._id,
            userId: state.user?._id,
            senderId: state.user?._id,
            toId: newChats[chatId].user?._id,
          });
        }
      }

      playSound();
      return {
        ...newState,
        chats: newChats,
        unreadMessages: getUnreadMessages(newChats),
        selectedChat: newSelectedChat,
      };
    });
  };
  useEffect(() => {
    if (socketService && isSocketConnected) {
      logger.debug(`Connecting to chat listeners`);
      socketService.onInitialMessages({
        func: data => {
          logger.debug(`==> onInitialMessages`);
          addMessageToChat({
            chatId: data.chatId,
            messages: data.messages,
            clearOldMessages: true,
          });
        },
      });

      socketService.onMessage({
        func: data => {
          logger.debug(`==> onMessage`);
          addMessageToChat({
            chatId: data.chatId,
            messages: [data.message],
            clearOldMessages: false,
          });
        },
        joinToDirectMessages: true,
        joinToAdminMessages: state.isAdmin,
      });

      socketService.onChatRead(({ chatId }) => {
        logger.debug(`==> onChatRead ${chatId}`);
        setAttendanceState(oldState => {
          const chats = { ...oldState.chats };
          const selectedChat = oldState.selectedChat
            ? {
                ...oldState.selectedChat,
              }
            : undefined;

          if (chats[chatId]) {
            chats[chatId].messages = (chats[chatId].messages || []).map(
              message => {
                return {
                  ...message,
                  wasRead: true,
                };
              },
            );
            chats[chatId].unreadMessages = 0;

            if (selectedChat?._id === chatId) {
              selectedChat.messages = (selectedChat.messages || []).map(
                message => ({
                  ...message,
                  wasRead: true,
                  readAt: Date.now(),
                }),
              );
              selectedChat.unreadMessages = 0;
            }
          }

          return {
            ...oldState,
            chats,
            selectedChat,
            unreadMessages: getUnreadMessages(chats),
          };
        });
      });

      loadChats();

      setAttendanceState(oldState => {
        return {
          ...oldState,
          isConnected: true,
        };
      });
    } else {
      setAttendanceState(oldState => {
        return {
          ...oldState,
          isConnected: false,
        };
      });
    }

    return () => {
      if (socketService) {
        socketService.clearOnChatRead();
        socketService.clearOnInitialMessages();
        socketService.clearOnMessage();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState, socketService, isSocketConnected]);

  useEffect(() => {
    return () => {
      if (socketService) {
        logger.debug(`Disconnecting from chat listeners`);
        socketService.closeChat();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AttendanceContext.Provider
      value={
        {
          attendanceState,
          getUserFromChat,
          loadChats,
          clear,
          setSelectedChat,
        } as AttendanceContextData
      }
    >
      <>{children}</>
    </AttendanceContext.Provider>
  );
};

function useAttendance(): AttendanceContextData {
  const context = useContext(AttendanceContext);

  if (!context) {
    throw new Error('useAttendance must be used within a AttendanceProvider');
  }

  return context;
}

export { AttendanceProvider, useAttendance };

import Link from 'next/link';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

export interface IAcceptTermsProps {
  onAccept: () => void;
}
const AcceptTerms = ({ onAccept }: IAcceptTermsProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <div className="flex flex-col items-center w-full">
      <div className="max-w-4xl max-h-full p-4 lg:p-8 space-y-8 font-poppins">
        <span className="text-xl font-bold text-primaryColor">
          Termos de uso
        </span>
        <div
          style={{ maxHeight: isTabletOrMobile ? '50vh' : '60vh' }}
          className="flex-grow overflow-y-scroll"
        >
          <div className="space-y-6">
            <p>
              TERMOS E CONDIÇÕES Condições Gerais de Utilização Introdução As
              presentes Condições Gerais de Utilização determinam as relações
              contratuais entre a Menobel Lda., proprietária do website (“site”)
              www.Menobel.com, aqui designada por “Menobel” e qualquer pessoa
              física ou moral que consulte – aqui designado por “Visitante” –
              e/ou que se registe na qualidade de cliente ou Nober – aqui
              designado por “Utilizador” – neste site. Ao usar este site,
              concorda com as condições de uso do mesmo, que inclui,
              nomeadamente, os termos e condições e a política de privacidade,
              que podem ser atualizadas ocasionalmente. Aconselhamo-lo,
              portanto, a visitar esta página regularmente para estar a par de
              quaisquer alterações que possam ter sido efetuadas às condições de
              uso. A utilização que é feita do site www.Menobel.com é da inteira
              responsabilidade do Utilizador. O acesso a este site é permitido
              com carácter temporário e reservamos o direito de retirar ou
              alterar serviços sem aviso prévio. Não nos responsabilizamos se,
              por qualquer razão, este site esteja indisponível num determinado
              período de tempo. Ocasionalmente, podemos restringir o acesso a
              algumas áreas deste site. A Menobel não se responsabiliza por
              danos ou perdas de qualquer pessoa, que possam surgir a partir da
              utilização de qualquer informação contida neste site. Apesar de
              serem feitos todos os esforços de forma a garantir a precisão e
              qualidade de informação e serviços prestados, a Menobel não
              garante que a informação própria e/ou de terceiros disponibilizada
              através deste site, bem como os serviços promovidos pelos
              profissionais, sejam completos, rigorosos e atualizados. A Menobel
              declina qualquer responsabilidade por eventuais erros publicados
              no site, incluindo erros de fotografias, imagens, preçários e
              tipográficos. A qualquer momento, a Menobel poderá alterar as
              condições gerais, o formato e o conteúdo presentes neste site,
              informando os Utilizadores assim que possível, não dependendo da
              produção dos efeitos das eventuais alterações da notificação aos
              respetivos Utilizadores da plataforma. As presentes Condições
              Gerais de Utilização deverão sempre ser lidas pelos Visitantes e
              Utilizadores antes de consultar e/ou utilizar a plataforma de
              negócio Menobel. A Menobel reserva-se no direito de extinguir o
              acesso a este site, a qualquer momento, sem notificação prévia.
              Definições Website: plataforma de negócio da exclusiva propriedade
              da Menobel Visitante: qualquer pessoa física ou moral que consulte
              o site da Menobel sem realizar qualquer registo na plataforma
              Utilizador: qualquer pessoa física ou moral que se registe na
              qualidade de cliente ou de profissional na plataforma de negócio
              Menobel Nober: qualquer pessoa que se registe no site da Menobel
              nessa qualidade a fim de promover e publicitar a prestação de
              determinados serviços para os quais se encontra devidamente
              habilitado. Cliente: qualquer pessoa que utilize o site da Menobel
              para interagir, efetuar pedidos de consultas e/ou de prestação de
              determinados serviços pelos profissionais inscritos para o efeito.
              Propriedade Intelectual O site www.Menobel.com e a marca
              registrada “Menobel” são propriedade da Menobel Lda. Os
              Utilizadores e Visitantes são avisados que o conteúdo integral
              deste website, incluindo, nomeadamente, textos, artigos,
              fotografias, imagens, ícones, desenhos, figuras, ilustrações,
              gráficos, formas, modelos, sons, vídeos, sequências animadas,
              estrutura, seleção, organização, apresentações, funcionalidades,
              softwares, produtos, serviços, marcas, logótipos, sinais,
              símbolos, emblemas e quaisquer outras informações, está protegido
              pelos direitos de propriedade intelectual e são propriedade
              exclusiva da Menobel e/ou dos respetivos criadores. Nestes termos,
              toda e qualquer reprodução, cópia, download, impressão,
              representação, utilização, difusão, distribuição, venda,
              transmissão, publicação, adaptação, modificação, complementação,
              incorporação, tradução ou qualquer outro uso, para comercialização
              ou não comercialização e quaisquer que sejam os meios utilizados,
              parciais ou integrais, destes conteúdos publicados neste site, sem
              a autorização escrita prévia da Menobel, são expressamente
              proibidos, sob pena de constituir crime de contrafação, usurpação,
              concorrência desleal e outros crimes puníveis. Política de
              privacidade Os seus dados são para uso exclusivo da Menobel. As
              informações recolhidas servem unicamente para prestar um serviço
              de qualidade, rigoroso e eficiente aos Utilizadores. Ao enviar-nos
              os seus dados, a Menobel assume que os estes foram introduzidos
              pelos seus titulares ou a sua recolha foi autorizada. A Menobel
              não fornece os seus dados a terceiros para fins comerciais,
              campanhas de marketing ou outros quaisquer fins. Poderá requerer a
              sua consulta, retificação ou eliminação para Menobel, R. João
              Lúcio de Azevedo, 22, A - 2750-568. Cascais - Portugal. A Política
              de Privacidade encontra-se pormenorizadamente descrita aqui:
              Condições da utilização da plataforma Menobel O site da Menobel é
              uma plataforma de mercado on-line que tem em vista a promoção e
              criação de relações comerciais entre o Nober´s e Clientes. Na
              plataforma de mercado Menobel, os Nober´s registados fazem constar
              do seu perfil informações acerca das suas competências, descrição
              dos serviços que prestam, formação académica, experiência
              profissional bem como outros elementos profissionais distintivos
              (à exceção dos contatos pessoais ou profissionais) que considerem
              relevantes para uma melhor apresentação e promoção de serviços
              junto do Cliente. Os Nober´s comprometem-se a inserir no seu
              perfil apenas informação correta e autêntica bem como e sempre que
              necessário documentação comprovativa respetiva. A qualquer
              momento, a Menobel pode exigir aos Nober´s prova documental
              respectiva a qualquer informação inserida no seu perfil. Caso a
              Menobel verifique que informação introduzida pelo Nober que não
              seja autêntica ou que o Nober não tenha forma de comprovar a sua
              veracidade, a Menobel poderá, a seu exclusivo critério, excluir do
              perfil essas referências inexactas ou, caso considere necessário
              para defesa dos legítimos interesses dos Clientes e/ou da própria
              Menobel, excluir o Nober em causa da plataforma de mercado de
              forma temporária ou definitiva. O Cliente analisa os diversos
              perfis dos Nober´s e, em função do seu interesse e necessidade na
              prestação de um determinado serviço, poderá entrar em contacto com
              o Nober pretendido, exclusivamente, através do chat e/ou
              videochamada que a plataforma Menobel disponibiliza aos
              Utilizadores para este efeito. Em momento algum os Utilizadores
              poderão trocar contatos pessoais e/ou profissionais entre si. O
              contato entre Nober e Cliente deverá ser sempre efetuado com
              recurso às ferramentas disponibilizadas pela plataforma de mercado
              Menobel. Iniciado o contato entre o Cliente e o Nober, este
              apresente diretamente àquele uma proposta de orçamento/honorários
              para os serviços solicitados. Compete ao Cliente avaliar a(s)
              proposta(s) de orçamento/honorários e respetivos serviços
              recolhidas, sendo a seleção do Nober e adjudicação do serviço da
              sua inteira e exclusiva responsabilidade. A Menobel limita-se a
              criar, gerir e manter em pleno funcionamento uma plataforma de
              mercado onde os Clientes com necessidades específicas procuram o
              Nober que melhor garanta a satisfação dessa necessidade. O Cliente
              pode, inclusivamente, após recolher uma ou mais propostas, optar
              por não adjudicar qualquer serviço aos Nober´s com quem manteve
              contato, reconhecendo, portanto, que a Menobel é terceiro na
              relação contratualmente estabelecida entre Cliente e Nober, não
              podendo nem garantido o cumprimento da prestação por parte do
              Profissional nem de qualquer transação que venha, entre eles, a
              ser acordada. Os Clientes terão o direito de avaliar, assinando ou
              anonimamente, o contato efetuado e/ou serviço prestado por
              qualquer Nober registrado no Menobel. Os Clientes poderão
              “Classificar” os Nober´s, tendo estes direito a responder,
              escrevendo uma “Anotação”. As classificações e as anotações
              refletem a opinião dos Utilizadores e não a opinião da Menobel. As
              classificações e as anotações não são verificados, nem filtrados
              pela Menobel, nem é da sua responsabilidade apurar a respetiva
              exatidão, podendo estes ser inexactos ou enganadores. As
              classificações e as anotações efectuadas pelos Clientes e Nober´s
              devem ser justas, verdadeiras e fatuais, não podendo conter
              terminologia ou linguagem difamatória ou que se possa considerar
              ofensiva. Os Utilizadores estão proibidos de manipular de qualquer
              forma o sistema de Classificações e Anotações, tal como dando
              indicações a um terceiro para escrever uma anotação positiva ou
              negativa sobre outro membro. A Menobel não se responsabiliza pela
              informação referida no número anterior, devendo o Cliente,
              previamente à seleção do Nober, avaliar e comprovar a veracidade,
              correção e integridade do mesmo. A apresentação, pelo Nober ao
              Cliente, de propostas fraudulentas ou de documentos falsos ou
              incorretos, em caso algum pode ser imputada à Menobel, pertencendo
              ao Cliente a responsabilidade pela seleção dos Nober´s e pela
              confirmação das informações por eles fornecidas. A Menobel reserva
              o direito de poder suspender ou eliminar os Utilizadores da
              plataforma e/ou conteúdos publicados que não estejam em
              conformidade com os termos e condições ou conteúdo que possa
              prejudicar a marca Menobel ou a imagem de Nober´s e clientes que
              utilizam esta plataforma de mercado. Direitos e Obrigações A
              adesão na qualidade de Cliente ou Nober às Condições Gerais de
              Utilização constitui a celebração de um contrato entre estes e a
              Menobel, resultando direitos e obrigações para cada uma das
              partes. A Menobel garante o funcionamento contínuo e correto do
              site, excepto nas situações em que, por motivos que lhe sejam
              alheios, tal cumprimento não lhe possa ser exigível. Não obstante,
              a Menobel reserva-se o direito, como proprietária do site, de
              suspender ou fazer cessar o seu funcionamento sempre que possua
              razões – relacionadas quer com terceiros, quer com o seu modelo de
              negócio – que o justifiquem, o que, em todo o caso, será, logo que
              possível, comunicado aos Utilizadores. Os Utilizadores obrigam-se
              a não utilizar a plataforma de mercado da Menobel, os seus
              conteúdos ou serviços para um fim distinto daquele a que a mesma
              se destina, nomeadamente para fins ilícitos, ilegais ou de
              qualquer forma capazes de colidir com direitos alheios legalmente
              protegidos. Os Nober´s obrigam-se a não oferecer a prestação de
              serviços não admitidos por lei, que sejam contrários à ordem
              pública, aos bons costumes ou que sejam proibidos pelas Condições
              Gerais de Utilização presentes. Os Clientes obrigam-se a não
              solicitar a prestação de serviços não admitidos por lei, que sejam
              contrários à ordem pública, aos bons costumes ou que sejam
              proibidos pelas Condições Gerais de Utilização presentes. Os
              Nober´s na construção do seu perfil, através da inclusão de
              informação que considerem útil e/ou necessária, deverão ter em
              conta o cumprimento da legislação aplicável em vigor, nomeadamente
              a respeitante a concorrência, proteção de direitos do consumidor,
              publicidade enganosa, descriminação e regras deontológicas de cada
              profissão. É proibido o uso de informações de carácter obsceno ou
              com conteúdos indecorosos, atentatórios dos bons costumes ou
              pornográficos, bem como são também proibidas as remissões e
              referências a outros sites, seja de que natureza forem. Não é
              permitido aos Nober´s a inserção de informação relativa aos seus
              contatos pessoais e/ou profissionais. Os Nober´s obrigam-se a
              prestar os serviços e a executar os trabalhos de acordo com as
              boas práticas das respetivas atividades profissionais e a
              respeitar os códigos deontológicos e regulamentos técnicos que as
              mesmas se enquadram. Os Clientes e Nober´s, em momento algum,
              poderão trocar contatos entre si. As comunicações entre os
              Utilizadores deverão efectuar-se, exclusivamente, através da
              plataforma com recurso aos meios disponibilizados para o efeito.
              Os Clientes e Nober´s comprometem-se a não efectuar quaisquer
              negócios, contratos, parcerias ou operações semelhantes, entre si,
              por outro qualquer meio informático ou não além da plataforma de
              mercado Menobel. Esta obrigação é válida pelo período que as
              partes possuam registo válido como Utilizador da plataforma de
              mercado. Os Clientes e Nober´s obrigam-se a não enviar a terceiros
              ou por outra forma divulgar informações constantes do site,
              distribuir ou exibir publicamente qualquer conteúdo do site sem
              prévia e expressa autorização da Menobel. Todas as informações
              prestadas pelos Utilizadores no ato do registo – quer na qualidade
              de Cliente quer de Nober – deverão ser reais e autênticas, sob
              pena de responderem pelos danos que tais informações inexactas
              possam vir a causar. Não obstante o previsto no número anterior, a
              Menobel não se considera responsável por qualquer erro, omissão,
              incorreção ou falsidade das informações transmitidas pelos
              Utilizadores. A violação de qualquer uma das obrigações previstas
              nos números anteriores ou análogas as estas, permite à Menobel
              suspender ou eliminar a conta do infractor, sendo este responsável
              por perdas e danos causados nos termos legais. Valor pela
              utilização da plataforma de mercado Menobel Para os Cliente a
              utilização da plataforma de mercado é gratuita. Os Profissionais
              pagarão um valor correspondente à(s) modalidade ativa(s). As
              modalidades disponíveis e respetivos valores associados podem ser
              consultados aqui. Pagamentos e Reembolsos Os pagamentos pelos
              serviços adjudicados serão efetuados pelos Clientes aos Nober´s
              através da plataforma Menobel. O Cliente paga o valor acordado
              entre as parte à Menobel e esta, volvidas 48 horas desde a receção
              do pagamento, entrega o montante devido ao Noberl, descontado o
              valor associado à(s) modalidade(s) subscrita e aplicável. Caso o
              Cliente não fique integralmente satisfeito com o serviço prestado
              pelo Nober poderá, num prazo de 48 horas após a realização do
              pagamento, solicitar o reembolso da quantia paga. O pedido de
              reembolso pelo Cliente deverá ser efetuado por escrito,
              preenchendo o formulário constante no site destinado ao efeito ou,
              em alternativa, remetendo um e-mail com o pedido de reembolso,
              indicação dos elementos identificativos do Profissional em causa,
              motivo de insatisfação e comprovativo de pagamento para o seguinte
              endereço: reembolsos@Menobel.com A reclamação deverá ser dirigida
              à Menobel que, após analisados os fundamentos invocado e
              considerando os mesmos procedentes, diligenciará pela devolução
              integral da quantia paga. As decisões dos pedidos de reembolso
              cabem exclusivamente à Menobel. A Menobel não tem a obrigação de
              ouvir o Nober para que a decisão de reembolsar o Cliente produza
              os seus efeitos. Dever de cooperação Sempre que um Cliente ou um
              Nober tenha conhecimento de comportamentos por parte de uma
              qualquer Utilizador contrários à lei, à ordem pública, aos bons
              costumes ou às presentes Condições Gerais de Utilização deverão
              informar de imediato a Menobel dessa situação. Responsabilidade da
              Menobel na relação entre Cliente e Nober A Menobel não é parte, a
              qualquer título, na relação contratualmente estabelecida entre o
              Cliente e o Profissional, pelo que estes reconhecem que não lhe
              pode ser imputada qualquer responsabilidade, nomeadamente no que
              respeita à concretização do contrato e ao cumprimento dos deveres
              contratuais das partes. De igual modo, não pode ser imputada à
              Menobel qualquer responsabilidade pelo incumprimento contratual de
              alguma das partes ou extracontratual, e, bem assim, por quaisquer
              perdas ou danos que daí possam resultar para alguma das partes.
              Sanções A Menobel tem o direito de esclarecer e apurar, por sua
              iniciativa e sempre que entenda necessário, as reclamações de que
              tenha conhecimento tendo em vista avaliar e decidir da permanência
              dos Clientes e Nober´s na sua plataforma de mercado. Qualquer
              Utilizador pode ser suspenso por determinado período ou eliminado
              definitivamente sempre que se demonstre ter sido violada alguma
              das obrigações resultantes das presentes Condições Gerais de
              Utilização. A decisão de suspensão ou eliminação cabe
              exclusivamente à Menobel. A Menobel não tem a obrigação de ouvir o
              infractor para que a decisão de aplicação de sanção produza os
              seus efeitos. A Menobel poderá ainda suspender ou terminar o
              serviço prestado pela utilização do seu site a um Cliente ou
              Profissional sempre que houver suspeita de existência de
              comportamentos contrários à lei, à ordem pública, aos bons
              costumes e às presentes Condições Gerais de Utilização.
              Disposições finais As presentes Condições Gerais de Utilização,
              assim como quaisquer acordos e documentos a que estas sejam
              aplicáveis são reguladas pela Lei Portuguesa. Em caso de litígio,
              na interpretação ou aplicação das presentes Condições Gerais de
              Utilização e a falta de acordo amigável entre as partes, é
              competente o foro da Comarca de Lisboa, com expressa renúncia a
              qualquer outro. A declaração de invalidade, ilegalidade ou
              ineficácia de qualquer disposição das presentes Condições Gerais
              de Utilização não afetará as restantes disposições, que
              continuarão em vigor. Nenhuma disposição constante das presentes
              Condições Gerais de Utilização constitui ou é passível de ser
              confundida com a constituição de qualquer relação de parceria,
              joint ventures, mandato, comissão ou outra além da que emergir do
              vínculo contratual constante do celebrado pela adesão ao presente
              acordo.
            </p>
          </div>
        </div>
        <div className="">
          <span>
            Ao concordar você assume estar ciente dos{' '}
            <span className="text-primaryColor">termos de uso</span> e da
            <Link href="/privacity">
              <a
                className="text-primaryColor"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                política de privacidade
              </a>
            </Link>
            .
          </span>
        </div>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 font-medium text-white bg-green-500 rounded font-poppins"
            onClick={() => {
              onAccept();
            }}
          >
            Concordo
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptTerms;

import { ROLES } from './constants';
import {
  CompanyIcon,
  HomeIcon,
  MoneyIcon,
  PhoneCallIcon,
  UserGroupIcon,
  UserIcon,
} from 'components/atoms/icons';
import { SidebarItemProps } from 'components/molecules/sidebars/Sidebar2/SidebarItem';
import React from 'react';
import { BiListPlus } from 'react-icons/bi';
import { VscGroupByRefType } from 'react-icons/vsc';
import { TOUR_IDS } from './tour';

export const TEACHER_SIDEBAR_LINKS: SidebarItemProps[] = [
  {
    id: TOUR_IDS.NAV.teacher.home,
    href: '/app/p',
    text: 'Inicio',
    icon: <HomeIcon />,
    acceptedRoles: [ROLES.TEACHER],
  },
  {
    id: TOUR_IDS.NAV.teacher.company,
    href: '/app/p/conta',
    text: 'Minha Conta',
    icon: <CompanyIcon />,
    acceptedRoles: [ROLES.TEACHER],
  },
  {
    id: TOUR_IDS.NAV.teacher.references,
    href: '/app/p/referencias',
    text: 'Referências',
    icon: <VscGroupByRefType />,
    acceptedRoles: [ROLES.TEACHER],
  },
  {
    id: TOUR_IDS.NAV.teacher.createReferences,
    href: '/app/p/referencias/criar',
    text: 'Criar referência',
    icon: <BiListPlus />,
    acceptedRoles: [ROLES.TEACHER],
  },
  {
    id: TOUR_IDS.NAV.teacher.attendances,
    href: '/app/p/atendimentos',
    text: 'Atendimentos',
    icon: <PhoneCallIcon />,
    acceptedRoles: [ROLES.TEACHER],
  },
  {
    id: TOUR_IDS.NAV.teacher.budgets,
    href: '/app/p/orcamentos',
    text: 'Orçamentos',
    icon: <MoneyIcon />,
    acceptedRoles: [ROLES.TEACHER],
  },
];

export const CLIENT_SIDEBAR_LINKS: SidebarItemProps[] = [
  {
    id: TOUR_IDS.NAV.client.home,
    href: '/app/c',
    text: 'Inicio',
    icon: <HomeIcon />,
    acceptedRoles: [ROLES.CLIENT],
  },
  {
    id: TOUR_IDS.NAV.client.attendances,
    href: '/app/c/atendimentos',
    text: 'Atendimentos',
    icon: <PhoneCallIcon />,
    acceptedRoles: [ROLES.CLIENT],
  },
  {
    id: TOUR_IDS.NAV.client.budgets,
    href: '/app/c/orcamentos',
    text: 'Orçamentos',
    icon: <MoneyIcon />,
    acceptedRoles: [ROLES.CLIENT],
  },
];

export const ADMIN_SIDEBAR_LINKS: SidebarItemProps[] = [
  {
    href: '/app/a',
    text: 'Temas',
    icon: <UserIcon />,
    acceptedRoles: [ROLES.ADMIN],
  },
  {
    href: '/app/a/referencias',
    text: 'Referencias',
    icon: <BiListPlus />,
    acceptedRoles: [ROLES.ADMIN],
  },
  {
    href: '/app/a/atendimentos',
    text: 'Atendimentos',
    icon: <PhoneCallIcon />,
    acceptedRoles: [ROLES.ADMIN],
  },
  {
    href: '/app/a/orcamentos',
    text: 'Orçamentos',
    icon: <MoneyIcon />,
    acceptedRoles: [ROLES.ADMIN],
  },
  {
    href: '/app/a/iprotubers',
    text: 'Nobers',
    icon: <UserGroupIcon />,
    acceptedRoles: [ROLES.ADMIN],
  },
  {
    href: '/app/a/clientes',
    text: 'Clientes',
    icon: <UserGroupIcon />,
    acceptedRoles: [ROLES.ADMIN],
  },
];

export const SIDEBAR_LINKS: SidebarItemProps[] = [
  ...TEACHER_SIDEBAR_LINKS,
  ...CLIENT_SIDEBAR_LINKS,
  ...ADMIN_SIDEBAR_LINKS,
];

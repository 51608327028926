import CardChat from 'components/atoms/CardChat';
import EmptyResult from 'components/molecules/EmptyResult';
import { Chats } from 'contexts/Attendances';
import React, { useCallback } from 'react';

export interface ILeftListChat {
  chats: Chats;
  extendsClassName?: string;
  onOpen?: () => void;
  onChatClick: (chatId: string) => void;
  selectedChatId?: string;
}
const LeftListChats = ({
  chats,
  onOpen,
  extendsClassName = '',
  onChatClick,
  selectedChatId,
}: ILeftListChat) => {
  const chatList = Object.entries(chats);

  const selectChat = useCallback(
    async (chatId: string) => {
      console.debug('Selecting chat', chatId);
      await onChatClick(chatId);
      onOpen();
    },
    [onChatClick, onOpen],
  );

  return (
    <>
      <div
        key={chatList.length}
        className={`flex flex-col h-full space-y-1 w-full pt-2 bg-secundaryColor overflow-y-scroll ${extendsClassName} `}
      >
        {chatList && chatList.length > 0 ? (
          chatList
            .sort(([chatIdA, chatA], [chatIdB, chatB]) => {
              return chatB.lastMessageAt - chatA.lastMessageAt;
            })
            .map(([chatId, chat]) => {
              return (
                <CardChat
                  key={chatId}
                  chat={chat}
                  isSelected={selectedChatId === chatId}
                  onClick={() => selectChat(chatId)}
                />
              );
            })
        ) : (
          <EmptyResult text="Parece que você ainda não iniciou chats." />
        )}
      </div>
    </>
  );
};

export default LeftListChats;

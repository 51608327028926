import React from 'react';

export interface ISidebarItemSeparatorProps {
  text: string;
}
const SidebarItemSeparator = ({ text }: ISidebarItemSeparatorProps) => {
  return (
    <li className="my-px">
      <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">
        {text}
      </span>
    </li>
  );
};

export default SidebarItemSeparator;

import { CheckDoubleIcon, CheckIcon, InfoIcon } from 'components/atoms/icons';
import Tooltip from 'components/atoms/Tooltip';
import React, { LegacyRef } from 'react';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';
import { dateToDatetimeString } from 'utils/DateUtils';

export interface IChatMessageTemplateProps {
  isSender: boolean;
  message: ChatMessage;
  children: React.ReactNode;
  ref?: LegacyRef<HTMLDivElement>;
  showSenderName?: boolean;
}

export const ChatMessageTemplate = ({
  isSender,
  message,
  children,
  ref,
  showSenderName,
}: IChatMessageTemplateProps) => {
  return (
    <div ref={ref} className="chat-message">
      <div className={`flex ${isSender ? 'justify-end' : ''}`}>
        <div
          className={`flex items-end ${
            isSender ? 'flex-row-reverse' : 'flex-row'
          }`}
        >
          <div
            className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2  ${
              isSender ? 'items-end' : 'items-start'
            }`}
          >
            {children}

            {showSenderName && message?.senderName && (
              <small className="font-extralight">{message?.senderName}</small>
            )}
          </div>
          <div
            className={`flex justify-end items-end ${
              isSender ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            {message.senderThumbnailUrl && (
              <img
                // src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                src={message.senderThumbnailUrl}
                alt="User thumbnail"
                className={`w-6 h-6 rounded-full`}
              />
            )}
            {isSender ? (
              message.wasRead && message.readAt ? (
                <Tooltip
                  text={`Lido em ${dateToDatetimeString(message.readAt)}`}
                >
                  <CheckDoubleIcon size={16} />
                </Tooltip>
              ) : (
                <Tooltip
                  text={`Enviado em ${dateToDatetimeString(message.createdAt)}`}
                >
                  <CheckIcon size={16} />
                </Tooltip>
              )
            ) : (
              <Tooltip
                text={`Recebida em ${dateToDatetimeString(message.createdAt)}`}
              >
                <InfoIcon size={16} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import {
  getUserNotifications,
  maskUserNotificationsAsSeen,
} from 'services/apiServices/IProTubeApi/notifications';
import useSWR from 'swr';
import { dateToTimeOrDateString } from 'utils/DateUtils';
import { useClickOutside } from 'hooks/useClickOutside';
import NotificationBadge from 'components/atoms/Badges/NotificationBadge';
import EmptyResult from 'components/molecules/EmptyResult';

/**
 * Based on https://tailwindcomponents.com/component/notifications-dropdown
 */
const TopbarNotifications = () => {
  const notificationsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  useClickOutside(notificationsRef, () => {
    setIsOpen(false);
  });
  const { data: notifications = [], mutate } = useSWR(
    `/notifications`,
    async () =>
      getUserNotifications({
        sorts: {
          'sort-createdAt': '-1',
        },
      }).then(({ results }) => results),
  );

  useEffect(() => {
    let unreadMessages_ = 0;

    if (notifications && Array.isArray(notifications)) {
      notifications.forEach(notification => {
        if (!notification.wasRead) unreadMessages_ += 1;
      });
    }

    setUnreadMessages(unreadMessages_);
  }, [notifications]);

  return (
    <div className="relative px-4">
      <button
        type="button"
        className="flex relative z-10 rounded-md p-2 focus:outline-none"
        onClick={() => {
          setIsOpen(is => !is);
        }}
      >
        <NotificationBadge
          text={
            unreadMessages > 0
              ? unreadMessages > 9
                ? '9+'
                : String(unreadMessages)
              : undefined
          }
        >
          <svg
            className="h-5 w-5 text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
          </svg>
        </NotificationBadge>
      </button>
      {isOpen === true && (
        <>
          {/* <div className="fixed inset-0 z-10"></div> */}
          <div
            ref={notificationsRef}
            className="absolute right-0 mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20"
          >
            <div className="w-48 max-h-64 overflow-y-auto overflow-x-hidden">
              {notifications && notifications.length > 0 ? (
                notifications.map(notification => {
                  return (
                    <div
                      key={notification._id}
                      className={`flex flex-col items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2 ${
                        notification.wasRead ? '' : 'bg-gray-100'
                      }`}
                    >
                      {/* <img
                      className="h-8 w-8 rounded-full object-cover mx-1"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                      alt="avatar"
                    /> */}
                      <p className="text-gray-600 text-sm mx-2">
                        <span className="font-bold">{notification.title}</span>{' '}
                        <span>{notification.text}</span>
                      </p>
                      <p className="w-full text-gray-600 text-sm text-right font-extralight">
                        {dateToTimeOrDateString(notification.createdAt)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="flex w-48 justify-center items-center p-4">
                  <EmptyResult text="Você ainda não tem notificações" />
                </div>
              )}
            </div>
            {unreadMessages > 0 && (
              <button
                type="button"
                className="w-full bg-gray-800 text-white text-center font-bold py-2"
                onClick={() => {
                  maskUserNotificationsAsSeen()
                    .catch(() => {
                      //
                    })
                    .finally(() => {
                      mutate(
                        notifications.map(notification => ({
                          ...notification,
                          wasRead: true,
                        })),
                      );
                    });
                }}
              >
                Marcar como lidas
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TopbarNotifications;

import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import SidebarItemSeparator from '../SidebarItemSeparator';

export type SidebarItemProps = {
  id?: string;
  icon?: React.ReactNode;
  text: string;
  activeClassName?: string;
  className?: string;
  href?: string;
  onClick?: () => void;
  items?: SidebarItemProps[];
  acceptedRoles?: string[];
  isDisabled?: boolean;
} & (SidebarItemWithLink | SidebarItemWithClick | SidebarMultipleItem);

export type SidebarItemWithLink = {
  href: string;
};
export type SidebarItemWithClick = {
  onClick: () => void;
};
export type SidebarMultipleItem = {
  text?: string;
  items: SidebarItemProps[];
};
const SidebarItem = ({
  id,
  text,
  icon,
  href,
  onClick,
  activeClassName = 'bg-gray-100 bg-primaryColor text-white',
  className = 'text-white hover:bg-gray-100 hover:bg-primaryColor hover:text-white',
  items,
  isDisabled,
}: SidebarItemProps) => {
  const itemId = String(Date.now());
  const [isActive, setIsActive] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (href) {
      setIsActive(router.asPath === href);
    }
  }, [router, href]);

  function getItemContent() {
    return (
      <>
        {icon && (
          <span className="flex items-center justify-center text-lg text-white">
            {icon}
          </span>
        )}

        <span className="ml-3 font-poppins text-sm">{text}</span>
      </>
    );
  }

  const anchorClassName = `flex flex-row items-center h-10 px-3 rounded-lg ${isActive ? activeClassName : className
    } ${isDisabled ? 'cursor-not-allowed opacity-25' : ''}`;

  return (
    <li className="my-px">
      {href && (
        <Link href={isDisabled ? {} : href}>
          <a
            id={id}
            className={anchorClassName}
            onClick={e => {
              isDisabled ? e.preventDefault() : null;
            }}
          >
            {getItemContent()}
          </a>
        </Link>
      )}

      {onClick && (
        <div
          onClick={() => {
            onClick();
          }}
          className={`cursor-pointer ${anchorClassName}`}
        >
          {getItemContent()}
        </div>
      )}
      {items && (
        <>
          <ul className="ml-5">
            <SidebarItemSeparator text={text} />
            {items.map((item, index) => {
              return (
                <SidebarItem key={`SidebarItem_${itemId}_${index}`} {...item} />
              );
            })}
          </ul>
        </>
      )}
    </li>
  );
};

export default SidebarItem;

import Tooltip from 'components/atoms/Tooltip';
import React from 'react';

export type CardStatusProps = {
  status: 'pending' | 'error' | 'success';
  text?: string;
  tooltip?: string;
  hiddenTextOnMobile?: boolean;
  icon?: React.ReactNode;
};
const CardStatus = ({
  tooltip = '',
  status,
  icon,
  text,
  hiddenTextOnMobile = true,
}: CardStatusProps) => {
  let colorsClassName = '';

  if (status === 'success') {
    colorsClassName = 'border-green-500 text-green-500';
  }

  if (status === 'error') {
    colorsClassName = 'border-red-500 text-red-500';
  }

  if (status === 'pending') {
    colorsClassName = 'border-yellow-500 text-yellow-500';
  }

  const hasIconAndText = !!icon && !!text;
  return (
    <Tooltip
      text={tooltip}
      className={`flex items-center font-poppins bg-transparent  border-2 p-2 text-xs rounded-md ${colorsClassName} ${
        hasIconAndText ? 'space-x-1' : ''
      }`}
    >
      {icon && <>{icon}</>}
      {text && (
        <span
          className={
            hasIconAndText && hiddenTextOnMobile ? 'hidden lg:flex' : ''
          }
        >
          {text}
        </span>
      )}
    </Tooltip>
  );
};

export default CardStatus;

import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

interface Props {
  name: string;
}
export type UnformTextAreaProps = Props & JSX.IntrinsicElements['textarea'];

export const UnformTextArea = ({ name, ...rest }: UnformTextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { fieldName, defaultValue = '', registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <textarea
      id={fieldName}
      name={name}
      ref={inputRef}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

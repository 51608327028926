import { CURRENCIES } from 'config/constants';
import { Attendance } from './Attendance.schema';
import { PaymentCard } from './PaymentCard.schema';
import { PaymentSplit } from './PaymentSplit.schema';
import { ServiceBudget } from './ServiceBudget.schema';
import { TeacherProfile } from './TeacherProfile.schema';
import { User } from './User.schema';

export const paymentStatus = ['PENDING', 'PAID', 'ERROR'] as const;
export const paymentType = ['CREDIT'] as const;

export class Payment {
  _id?: string;
  stripePaymentIntentId?: string;
  status?: typeof paymentStatus[number];
  type: typeof paymentType[number];
  currency: typeof CURRENCIES[number];
  attendance?: string | Attendance;
  serviceBudget?: string | ServiceBudget;
  client: string | User;
  teacher: string | User;
  teacherProfile: string | TeacherProfile;
  card?: PaymentCard;

  /**
   * Preço total do atendimento em centavos
   */
  amount?: number;
  split?: PaymentSplit;
  isDeleted?: boolean;
  paidAt?: number;
  createdAt?: number;
}
export const getPaymentStatusLabel = (
  status: typeof paymentStatus[number],
): string => {
  if (status === 'PAID') {
    return 'Realizado';
  }

  if (status === 'PENDING') {
    return 'Pendente';
  }

  if (status === 'ERROR') {
    return 'Falho';
  }

  return '-';
};

export const ALLOWED_VIDEO_MIMES = ['video/mp4', 'video/ogg'];
export const ALLOWED_IMAGE_MIMES = ['image/jpeg', 'image/pjpeg', 'image/png'];
export const ALLOWED_AUDIO_MIMES = ['audio/ogg', 'audio/mpeg'];
export const ALLOWED_FILE_MIMES = [
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
];

export const ALL_ALLOWED_FILE_MIMES = [
  ...ALLOWED_VIDEO_MIMES,
  ...ALLOWED_IMAGE_MIMES,
  ...ALLOWED_AUDIO_MIMES,
  ...ALLOWED_FILE_MIMES,
];

export const getHtmlAcceptFromMimeTypes = (mimetypes: string[]): string => {
  return mimetypes.join(',');
};

import { showClientCardsModal } from 'components/organisms/Clients/ClientCardsModal';
import { useAuth } from 'contexts/Authentication';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { Attendance } from 'services/apiServices/IProTubeApi/schemas/Attendance.schema';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';
import { dateToDatetimeString } from 'utils/DateUtils';
import SystemTextMessage from './SystemTextMessage';

// import { Container } from './styles';

export interface AttendanceActionMessageProps {
  message: ChatMessage;
}
const AttendanceActionMessage = ({ message }: AttendanceActionMessageProps) => {
  const { state, reloadUser } = useAuth();
  const router = useRouter();

  const attendance = message.attendance as Attendance;

  const href = `/app/${state?.isTeacher ? 'p' : 'c'}/atendimentos/${
    message.src
  }`;

  let anchorLabel = 'Ir para atendimento';
  let systemMessage = 'Um atendimento foi criado !';

  if (attendance?.status === 'FINISHED') {
    systemMessage = `Atendimento de ${(attendance.duration / 60).toFixed(
      1,
    )} realizado.`;
    anchorLabel = 'Ver resumo do atendimento realizado';
  }

  const clickHereButton = useMemo(
    () => (
      <Link href={href}>
        <a className="font-bold">aqui</a>
      </Link>
    ),
    [href],
  );

  const getGoToAttendanteButton = useCallback(() => {
    return (
      <span
        className={`w-full px-4 text-sm py-2 rounded-lg inline-block font-semibold text-left`}
      >
        Clique {clickHereButton} para ir para o atendimento n°{' '}
        {attendance?.code || ''}
      </span>
    );
  }, [clickHereButton, attendance]);

  const getClientButton = useCallback(() => {
    if (!state.user.canBeCharged) {
      return (
        <button
          onClick={() => {
            showClientCardsModal({
              user: state.user,
              onFinish: success => {
                if (success) {
                  reloadUser()
                    .then(() => {
                      router.push(href);
                    })
                    .catch(() => {
                      //
                    });
                }
              },
            });
          }}
          className={`w-full px-4 text-sm py-2 rounded-lg inline-block font-semibold text-left`}
        >
          Clique <strong>aqui</strong> para cadastrar seu cartão e iniciar o
          atendimento n° {attendance?.code || ''}
        </button>
      );
    }

    return getGoToAttendanteButton();
  }, [
    attendance?.code,
    getGoToAttendanteButton,
    href,
    reloadUser,
    router,
    state.user,
  ]);

  return (
    <>
      <SystemTextMessage>
        {attendance.status === 'FINISHED' ? (
          <span
            className={`w-full px-4 text-sm py-2 rounded-lg inline-block font-semibold text-left`}
          >
            Clique {clickHereButton} para ver detalhes do atendimento
            atendimento n° {attendance?.code || ''} finalizado em{' '}
            {dateToDatetimeString(attendance.finishedAt || 0)}
          </span>
        ) : state.isClient ? (
          getClientButton()
        ) : (
          getGoToAttendanteButton()
        )}
      </SystemTextMessage>
    </>
  );
};

export default AttendanceActionMessage;

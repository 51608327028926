import { IChatMessageProps } from 'components/molecules/Chat/components/Message';
import { PreparedChat } from 'services/apiServices/IProTubeApi/schemas/Chat';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';

export const getMessageProps = (
  message: ChatMessage,
  chat: PreparedChat,
  userId: string,
): Pick<IChatMessageProps, 'type' | 'showSenderName'> => {
  let showSenderName = false;

  const senderId = message.user;
  const isSender = senderId === userId;

  const type: IChatMessageProps['type'] = isSender ? 'sent' : 'received';

  console.debug({
    message,
    chat,
  });

  if (chat.withAdmins) {
    showSenderName = true;
  }
  return {
    type,
    showSenderName,
  };
};

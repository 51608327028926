export const TOUR_IDS = {
  any: 'asdasd',
  fullScreen: 'layout-fullscreen',
  NAV: {
    logout: 'nav-logout-button',
    support: 'nav-support-button',
    close: 'nav-close-button',
    open: 'nav-open-button',
    teacher: {
      home: 'teacher-nav-home-link',
      profile: 'teacher-nav-profile-link',
      company: 'teacher-nav-company-link',
      references: 'teacher-nav-references-link',
      createReferences: 'teacher-nav-create-references-link',
      attendances: 'teacher-nav-attendances-link',
      budgets: 'teacher-nav-budgets-link',
    },
    client: {
      home: 'client-nav-home-link',
      profile: 'client-nav-profile-link',
      company: 'client-nav-company-link',
      attendances: 'client-nav-attendances-link',
      budgets: 'client-nav-budgets-link',
    },
  },
  PROFILE: {
    thumbnail: 'profile-user-thumbnail',
    biography: 'profile-user-biography',
    social: 'profile-user-social',
    personal: 'profile-user-personal',
  },
};

import dynamic from 'next/dynamic';
import React from 'react';
import { TooltipProps } from 'react-tooltip';
const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false });

export interface ITooltip extends TooltipProps {
  text: string;
  children: React.ReactNode;
  className?: string;
}
const Tooltip = ({ children, text, className }: ITooltip) => {
  const randomId = String(Date.now());
  return (
    <div data-tip={text} data-for={randomId} className={className}>
      <ReactTooltip id={randomId} place="right" type="dark" effect="float" />
      {children}
    </div>
  );
};

export default Tooltip;

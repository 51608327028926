import NotAuthenticated from 'components/organisms/Authentication/NotAuthenticated';
import NotAuthorized from 'components/organisms/Authentication/NotAuthorized';
import RecognizingUser from 'components/organisms/Authentication/RecognizingUser';
import { useAuth } from 'contexts/Authentication';
import { useRouter } from 'next/dist/client/router';
import React, { useCallback, useEffect, useState } from 'react';

export interface IWithAuth {
  children: React.ReactNode;
  acceptedRoles?: string[];
}
const WithAuthContainer = ({ children, acceptedRoles = [] }: IWithAuth) => {
  const router = useRouter();
  const { state, isUserAuthenticated, isRecognizingState } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const getIsAuthorized = useCallback(() => {
    if (!state.user || !state.firebaseUser) return false;

    return (
      acceptedRoles.length === 0 ||
      state.user.roles.some(userRole => acceptedRoles.includes(userRole))
    );
  }, [acceptedRoles, state]);
  const [isAuthorized, setIsAuthorized] = useState(getIsAuthorized());

  useEffect(() => {
    if (!isRecognizingState) {
      if (isUserAuthenticated()) {
        setIsAuthorized(getIsAuthorized());
      }
    }
    setIsLoading(isRecognizingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecognizingState]);

  if (isAuthorized) return <>{children}</>;

  if (isLoading || isRecognizingState) {
    return (
      <>
        <RecognizingUser />
      </>
    );
  }
  if (!isUserAuthenticated()) {
    return <NotAuthenticated />;
  }
  return <NotAuthorized />;
};

export const withAuth = (component: React.ReactNode, roles?: string[]) => {
  return (
    <WithAuthContainer acceptedRoles={roles}>{component}</WithAuthContainer>
  );
};

export default withAuth;

import { DEFAULT_THUMBNAIL_URL } from 'config/constants';
import React from 'react';
import { PreparedChat } from 'services/apiServices/IProTubeApi/schemas/Chat';
import { dateToTimeOrDateString } from 'utils/DateUtils';
import NotificationBadge from '../Badges/NotificationBadge';

export type CardChatProps = {
  chat: PreparedChat;
  showThumbnail?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
};

const CardChat = ({
  chat,
  showThumbnail = true,
  onClick,
  isSelected,
}: CardChatProps) => {
  const lastMessage = chat.lastMessage || '';
  const messageTime = dateToTimeOrDateString(chat.lastMessageAt || 0);
  return (
    <>
      <div
        className={`flex w-full max-w-full rounded-lg px-2 items-center bg-grey-light cursor-pointer border-opacity-70 text-white ${
          isSelected
            ? 'bg-primaryColor bg-opacity-100'
            : 'border-gray-600 hover:bg-gray-600 bg-opacity-40 transition-all delay-100'
        }`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {showThumbnail && (
          <NotificationBadge
            text={chat.unreadMessages > 0 && String(chat.unreadMessages || '')}
          >
            <img
              className="h-6 w-6 xl:h-10 xl:w-12 rounded-full"
              src={chat.user?.photoUrl || DEFAULT_THUMBNAIL_URL}
            />
          </NotificationBadge>
        )}

        <div className="ml-4 flex-1 w-full py-4 max-w-full border-b border-primaryColor border-opacity-20">
          <div className="flex items-center justify-between">
            <p className="text-grey-darkest font-semibold">
              {chat.user?.name || ''}
            </p>

            {/* {chat.unreadMessages && (
              <p className="text-xs  text-grey-darkest ">
                {chat.unreadMessages}
              </p>
            )} */}
          </div>

          {lastMessage && (
            <p className="text-grey-dark w-4/3 mt-1 text-sm font-light line-clamp-1 opacity-80">
              {lastMessage}
            </p>
          )}
          <div className="flex justify-end">
            {messageTime && (
              <p className="text-xs  text-grey-darkest ">{messageTime}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardChat;

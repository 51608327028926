import { useMediaQuery } from 'react-responsive';
import React, { createContext, useState, useContext, useEffect } from 'react';

interface SidebarContextState {
  isOpen: boolean;
  isMobile: boolean;
  isDesktop: boolean;
}
interface SidebarContextContextData {
  state: SidebarContextState;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarContextContext = createContext<SidebarContextContextData>({
  state: {},
} as SidebarContextContextData);

const SidebarContextProvider: React.FC = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(true);
    }
  }, [isDesktop]);

  return (
    <SidebarContextContext.Provider
      value={
        {
          state: {
            isOpen,
            isMobile: !isDesktop,
            isDesktop,
          },
          setIsOpen,
        } as SidebarContextContextData
      }
    >
      <>{children}</>
    </SidebarContextContext.Provider>
  );
};

function useSidebarContext(): SidebarContextContextData {
  const context = useContext(SidebarContextContext);

  if (!context) {
    throw new Error(
      'useSidebarContext must be used within a SidebarContextProvider',
    );
  }

  return context;
}

export { SidebarContextProvider, useSidebarContext };

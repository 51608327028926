import React from 'react';
import { AiOutlineInfo } from 'react-icons/ai';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';

// import { Container } from './styles';

export interface SystemTextMessageProps {
  message?: ChatMessage;
  text?: string;
  children?: React.ReactNode;
}
const SystemTextMessage = ({
  message,
  text,
  children,
}: SystemTextMessageProps) => {
  const textMessage = message?.src || text;
  return (
    <div className="bg-yellow-100 rounded-lg p-2 border-t-4 border-yellow-500">
      <div className="flex w-full justify-start items-center">
        <AiOutlineInfo />
        <small className="font-light text-sm text-black opacity-70 font-roboto">
          Mensagem do sistema
        </small>
      </div>
      <div className="flex flex-col w-full justify-start items-center">
        {textMessage && (
          <span
            className={`w-full px-4 text-sm py-1 rounded-lg inline-block font-semibold text-left`}
          >
            {message?.src || text || <>{children}</>}
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

export default SystemTextMessage;

import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
// import './Styles.css';
const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      color: '#fafafa',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'black',
      },
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};
function StripeCreditCard() {
  return (
    <label className="text-primaryColor">
      <CardElement />
    </label>
  );
}
export default StripeCreditCard;

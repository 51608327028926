import api from '../iProTubeApi';
import { ServiceBudget } from '../schemas/ServiceBudget.schema';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';
import {
  CreateServiceBudgetDTO,
  UpdateServiceBudgetDTO,
  GetServiceBudgetOptions,
  GetServiceBudgetsOptions,
} from './types';

const endpoint = '/service-budgets';

export async function createServiceBudget(
  dto: CreateServiceBudgetDTO,
): Promise<ServiceBudget> {
  return await api.post(`${endpoint}`, dto).then(({ data }) => data);
}

export async function updateServiceBudget(
  id: string,
  dto: UpdateServiceBudgetDTO,
): Promise<ServiceBudget> {
  return await api.put(`${endpoint}/${id}`, dto).then(({ data }) => data);
}
export async function patchServiceBudget(
  id: string,
  dto: Partial<UpdateServiceBudgetDTO>,
): Promise<ServiceBudget> {
  return await api.patch(`${endpoint}/${id}`, dto).then(({ data }) => data);
}

export async function deleteServiceBudget(id: string): Promise<void> {
  return await api.delete(`${endpoint}/${id}`);
}

export async function getServiceBudgets(
  options?: GetServiceBudgetsOptions,
): Promise<PaginatedResponse<ServiceBudget>> {
  return await api
    .get(`${endpoint}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getServiceBudget(
  id: string,
  options?: GetServiceBudgetOptions,
): Promise<ServiceBudget> {
  return await api
    .get(`${endpoint}/${id}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function acceptServiceBudget(id: string): Promise<void> {
  return await api.post(`${endpoint}/${id}/accept`);
}

export async function denyServiceBudget(id: string): Promise<void> {
  return await api.post(`${endpoint}/${id}/deny`);
}

export async function removeServiceBudget(id: string): Promise<void> {
  return await api.post(`${endpoint}/${id}/remove`);
}

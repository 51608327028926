import React, { useRef } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import CreateServiceLive from '../../../lotties/create-service-live.json';
import { Modal } from 'react-responsive-modal';
import { useMediaQuery } from 'react-responsive';
import FormButtonSubmit from 'components/Forms/FormButtonSubmit';

interface Props {
  children: React.ReactNode;
  action: string;
  imgPath?: string;
  title?: string;
  isOpen: boolean;
  isImagem?: boolean;
  isGif?: boolean;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any, obj: any) => void;
}

const FormModal = ({
  children,
  imgPath,
  action,
  title,
  isOpen,
  isImagem,
  isGif,
  handleClose,
  onSubmit,
}: Props) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const formRef = useRef<FormHandles>(null);

  // create-service-live

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CreateServiceLive,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      styles={{
        root: {
          borderRadius: '12px',
          alignContent: 'center',
          alignSelf: 'center',
        },
      }}
    >
      <section className="w-full flex items-center">
        {isDesktop && (
          <div className="flex flex-col  items-center justify-start w-2/4">
            <h1 className="text-xl text-titleColor font-medium">{title}</h1>
            {isImagem && (
              <img
                src={imgPath}
                alt="Animação do formulário"
                width={300}
                height={300}
              />
            )}

            {isGif && (
              <Lottie options={defaultOptions} height={250} width={250} />
            )}
          </div>
        )}

        <div className="flex w-full flex-col py-8 md:p-10">
          <Form ref={formRef} onSubmit={onSubmit}>
            <div className="flex flex-col">
              {!isDesktop && (
                <h1 className="text-xl text-titleColor font-medium">{title}</h1>
              )}
              {children}
              <div className="flex flex-row justify-end mt-5">
                <div />
                <FormButtonSubmit buttonText={action} />
              </div>
            </div>
          </Form>
        </div>
      </section>
    </Modal>
  );
};

export default FormModal;

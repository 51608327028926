import { Form } from '@unform/web';
import { InputTextGroup } from 'components/Forms/InputGroup';
import { TextAreaInputGroup } from 'components/Forms/InputGroup/TextAreaInputGroup';
import CardPaymentStatus from 'components/atoms/Cards/CardPaymentStatus';
import CardStatus from 'components/atoms/Cards/CardStatus';
import React, { useEffect, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import {
  getServiceBudgetStatusLabel,
  ServiceBudget,
} from 'services/apiServices/IProTubeApi/schemas/ServiceBudget.schema';
import { getServiceBudget } from 'services/apiServices/IProTubeApi/serviceBudgets';
import { SweetAlertResult } from 'sweetalert2';
import {
  ReactSwal,
  showHandledErrors,
  showMessageStatus,
} from 'utils/SwalUtils';

interface CreateServiceProps {
  serviceBudgetId: string;
  onAccept?: (serviceBudget: ServiceBudget) => void;
  onDeny?: (serviceBudget: ServiceBudget) => void;
}

const ShowBudgetModal = ({
  serviceBudgetId,
  onAccept,
  onDeny,
}: CreateServiceProps) => {
  const [serviceBudget, setServiceBudget] = useState<ServiceBudget | undefined>(
    undefined,
  );

  useEffect(() => {
    if (serviceBudgetId) {
      getServiceBudget(serviceBudgetId, {
        populates: {
          'populate-client': true,
          'populate-teacher': true,
          'populate-teacherProfile': true,
        },
      })
        .then(setServiceBudget)
        .catch(err => {
          showHandledErrors(err, {
            showUnhandledModal: true,
          }).catch(() => {
            //
          });
        });
    }
  }, [serviceBudgetId]);

  if (!serviceBudget) {
    return <div></div>;
  }

  const statusLabel = getServiceBudgetStatusLabel(serviceBudget.status);

  const serviceBudgetStatus =
    serviceBudget?.status === 'ACCEPTED'
      ? 'success'
      : serviceBudget?.status === 'DENIED'
      ? 'error'
      : 'pending';

  return (
    <>
      <div className="flex w-full h-full justify-center items-center">
        <Form
          onSubmit={() => {
            //
          }}
        >
          <span className="font-bold py-2 mb-2">
            Orçamento {serviceBudget.code}
          </span>

          <div className="flex flex-row w-full justify-between mb-2 mt-4">
            <CardStatus
              icon={<CgFileDocument />}
              status={serviceBudgetStatus}
              text={statusLabel}
              tooltip="Status do orçamento"
            />

            {serviceBudget?.status !== 'DENIED' && (
              <CardPaymentStatus status={serviceBudget?.paymentStatus} />
            )}
          </div>
          <div className="my-2 text-left mt-4">
            <label
              htmlFor="title"
              className="text-titleColor font-medium ml-2 mb-2 lg:text-base"
            >
              Titulo
            </label>
            <InputTextGroup
              name="title"
              type="text"
              className="mt-4 p-4 w-full text-titleColor bg-white bg-opacity-10 rounded-lg lg:text-base shadow-md"
              placeholder="Dê um nome para este orçamento"
              required={true}
              defaultValue={serviceBudget.title}
              readOnly={true}
            />
          </div>

          <div className="my-2 text-left">
            <label
              htmlFor="description"
              className="text-titleColor font-medium ml-2 mb-2 lg:text-base"
            >
              Descrição
            </label>
            <TextAreaInputGroup
              name="description"
              className="mt-4 p-4 w-full text-titleColor bg-white bg-opacity-10 rounded-lg lg:text-base shadow-md resize-none"
              placeholder="Escreva uma descrição para esse orçamento"
              required={true}
              defaultValue={serviceBudget.description}
              readOnly={true}
            />
          </div>

          <div className="my-2 text-left">
            <label
              htmlFor="amount"
              className="text-titleColor font-medium ml-2 mb-2 lg:text-base "
            >
              Preço (R$)
            </label>
            <InputTextGroup
              name="amount"
              type="number"
              step="0.01"
              className="mt-4 p-4 w-full text-titleColor bg-white bg-opacity-10 rounded-lg lg:text-base shadow-md"
              placeholder="Quanto vai custar ?"
              required={true}
              defaultValue={String(serviceBudget.amount / 100)}
              readOnly={true}
            />
          </div>
        </Form>
      </div>

      {serviceBudget.status === 'PENDING' && (
        <div className="flex w-full h-full justify-between mt-6">
          {onDeny && (
            <button
              className="bg-red-700 rounded-md p-4 text-white"
              onClick={() => {
                if (onDeny) {
                  showMessageStatus({
                    titleContent: 'Tem certeza que deseja negá-lo?',
                    imageType: 'question',
                    textContent: `Este orçamento será finalizado.`,
                    textButton: 'Sim',
                    textCancelButton: 'Não',
                    onIsAccepted: () => {
                      onDeny(serviceBudget);
                    },
                  });
                }
              }}
            >
              Negar
            </button>
          )}

          {onAccept && (
            <button
              className="bg-green-700 rounded-md p-4 text-white"
              onClick={() => {
                if (onAccept) {
                  showMessageStatus({
                    titleContent: 'Tem certeza que deseja aceitá-lo?',
                    imageType: 'question',
                    textContent: `O valor do orçamento será cobrado do seu cartão de crédito`,
                    textButton: 'Confirmar',
                    textCancelButton: 'Cancelar',
                    onIsAccepted: () => {
                      onAccept(serviceBudget);
                    },
                  });
                }
              }}
            >
              Aceitar
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ShowBudgetModal;

export const showShowBudgetModal = (
  props: CreateServiceProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<SweetAlertResult<any>> => {
  return ReactSwal.fire({
    html: <ShowBudgetModal {...props} />,
    showConfirmButton: false,
  });
};

import VisibleArea from 'layouts/visibleArea';
import { useRouter } from 'next/router';
import React from 'react';

const NotAuthenticated = () => {
  const router = useRouter();
  return (
    <>
      <VisibleArea>
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-primaryColor opacity-75 flex flex-col items-center justify-center">
          <h2 className="text-center text-white text-xl font-semibold">
            Você precisa fazer login antes de seguir adiante
          </h2>
          <button
            className="w-1/3 text-center text-primaryColor font-semibold bg-white rounded-md mt-5 p-2"
            type="button"
            onClick={() => {
              router.push({
                pathname: '/login',
                query: {
                  returnUrl: router.asPath,
                },
              });
            }}
          >
            Login
          </button>
        </div>
      </VisibleArea>
    </>
  );
};

export default NotAuthenticated;

import React from 'react';
import { ChatMessageTemplate } from '../ChatMessageTemplate';
import ChatTextMessage, { IChatTextMessageProps } from './TextMessage';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';
import SystemTextMessage from './SystemTextMessage';
import AttendanceActionMessage from './AttendanceActionMessage';
import ServiceBudgetActionMessage from './ServiceBudgetActionMessage';
import Zoom from 'react-medium-image-zoom';

export interface IChatMessageProps extends Partial<IChatTextMessageProps> {
  type: 'sent' | 'received';
  message: ChatMessage;
  showSenderName?: boolean;
}

const ChatMessageComponent = (props: IChatMessageProps) => {
  const { message, showSenderName } = props;

  const isSender = props.type === 'sent';

  if (message.type === 'text') {
    return (
      <ChatMessageTemplate
        isSender={isSender}
        message={message}
        showSenderName={showSenderName}
      >
        <ChatTextMessage {...props} isSender={isSender} />
      </ChatMessageTemplate>
    );
  }
  if (message.type === 'image') {
    return (
      <ChatMessageTemplate
        isSender={isSender}
        message={message}
        showSenderName={showSenderName}
      >
        <Zoom zoomMargin={40}>
          <img src={message.src} className="w-auto h-24" />
        </Zoom>
      </ChatMessageTemplate>
    );
  }

  if (message.type === 'video') {
    return (
      <ChatMessageTemplate
        isSender={isSender}
        message={message}
        showSenderName={showSenderName}
      >
        <video width="320" height="240" controls>
          <source src={message.src} />
        </video>
      </ChatMessageTemplate>
    );
  }

  if (message.type === 'audio') {
    return (
      <ChatMessageTemplate
        isSender={isSender}
        message={message}
        showSenderName={showSenderName}
      >
        <audio controls>
          <source src={message.src} />
        </audio>
      </ChatMessageTemplate>
    );
  }

  if (message.type === 'system-text') {
    return <SystemTextMessage {...props} />;
  }

  if (message.type === 'action' && message?.action?.type === 'ATTENDANCE') {
    return <AttendanceActionMessage {...props} />;
  }

  if (message.type === 'action' && message?.action?.type === 'SERVICE_BUDGET') {
    return <ServiceBudgetActionMessage {...props} />;
  }

  return <></>;
};

export default ChatMessageComponent;

import React from 'react';

export interface IVisibleAreaProps {
  children: React.ReactNode;
  flexRowLayout?: boolean;
  flexColLayout?: boolean;
}
export const VisibleArea = ({
  children,
  flexColLayout,
  flexRowLayout,
}: IVisibleAreaProps) => {
  return (
    <>
      <section
        className={
          'h-screen w-screen max-h-screen ' +
          `${flexColLayout ? 'flex flex-col justify-center' : ''} ${
            flexRowLayout ? 'flex flex-row' : ''
          }`
        }
      >
        {children}
      </section>
    </>
  );
};

export default VisibleArea;

import api from '../iProTubeApi';
import { ChatMessage } from '../schemas/ChatMessage';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';
import {
  CreateChatMessageDTO,
  UpdateChatMessageDTO,
  GetChatMessageOptions,
  GetChatMessagesOptions,
} from './types';

const endpoint = '/chat-messages';

export async function createChatMessage(
  id: string,
  dto: CreateChatMessageDTO,
): Promise<ChatMessage> {
  return await api.post(`${endpoint}/${id}`, dto).then(({ data }) => data);
}

export async function updateChatMessage(
  id: string,
  dto: UpdateChatMessageDTO,
): Promise<ChatMessage> {
  return await api.put(`${endpoint}/${id}`, dto).then(({ data }) => data);
}

export async function patchChatMessage(
  id: string,
  dto: Partial<UpdateChatMessageDTO>,
): Promise<ChatMessage> {
  return await api.patch(`${endpoint}/${id}`, dto).then(({ data }) => data);
}
export async function deleteChatMessage(id: string): Promise<void> {
  return await api.delete(`${endpoint}/${id}`);
}

export async function getChatMessages(
  options?: GetChatMessagesOptions,
): Promise<PaginatedResponse<ChatMessage>> {
  return await api
    .get(`${endpoint}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getChatMessage(
  id: string,
  options?: GetChatMessageOptions,
): Promise<ChatMessage> {
  return await api
    .get(`${endpoint}/${id}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

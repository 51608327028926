import { Chat } from './Chat';
import {
  getPaymentStatusLabel,
  Payment,
  paymentStatus,
} from './Payment.schema';
import { TeacherProfile } from './TeacherProfile.schema';
import { User } from './User.schema';
import { CURRENCIES } from 'config/constants';

export const serviceBudgetStatus = [
  'PENDING',
  'ACCEPTED',
  'DENIED',
  'REMOVED',
] as const;

export class ServiceBudget {
  _id?: string;
  code?: string;
  status?: typeof serviceBudgetStatus[number];
  paymentStatus?: typeof paymentStatus[number];
  teacher: string | User;
  client: string | User;
  chat: string | Chat;
  teacherProfile?: string | TeacherProfile;
  payment?: string | Payment;
  title: string;
  description: string;
  currency?: typeof CURRENCIES[number];
  amount: number;
  isDeleted?: boolean;
  createdAt?: number;
}

export const getServiceBudgetStatusLabel = (
  status: typeof serviceBudgetStatus[number],
): string => {
  if (status === 'ACCEPTED') {
    return 'Aceito';
  }

  if (status === 'DENIED') {
    return 'Negado';
  }

  if (status === 'PENDING') {
    return 'Pendente';
  }

  return '-';
};

export const getServiceBudgetPaymentStatusLabel = (
  status: typeof paymentStatus[number],
): string => {
  return getPaymentStatusLabel(status);
};

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export type SimpleFileUploadContainerProps = {
  disabled?: boolean;
  accept: string;
  onFileUploaded: (file: File) => void;
  children: React.ReactNode;
};

const SimpleFileUploadContainer = ({
  disabled = false,
  accept,
  onFileUploaded,
  children,
}: SimpleFileUploadContainerProps) => {
  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop,
    accept,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} accept={accept} />
      {children}
    </div>
  );
};

export default SimpleFileUploadContainer;

import NotificationBadge from 'components/atoms/Badges/NotificationBadge';
import { MessageIcon } from 'components/atoms/icons';
import Tooltip from 'components/atoms/Tooltip';
import ChatInIProTuberDashboard from 'components/molecules/ChatInIProTuberDashboard';
import Sidebar from 'components/molecules/sidebars/Sidebar2';
import {
  SidebarContextProvider,
  useSidebarContext,
} from 'components/molecules/sidebars/Sidebar2/SidebarContext';
import Topbar from 'components/molecules/Topbars/Topbar';
import { DEFAULT_THUMBNAIL_URL, GTM_ID } from 'config/constants';
import { TOUR_IDS } from 'config/tour';
import { AttendanceProvider, useAttendance } from 'contexts/Attendances';
import { useAuth } from 'contexts/Authentication';
import { useFloatingChat } from 'contexts/FloatingChat';
import { useRouter } from 'next/router';
import React, { LegacyRef, useEffect, useState } from 'react';
import { BiSupport } from 'react-icons/bi';
import { createChat } from 'services/apiServices/IProTubeApi/chats';
import { CreateChatDTO } from 'services/apiServices/IProTubeApi/chats/types';
import { getAccountStatus } from 'services/apiServices/IProTubeApi/stripe';
import { AccountStatusResponse } from 'services/apiServices/IProTubeApi/stripe/responses/AccountStatus.response';
import useAnalytics from 'shared/hooks/useAnalytics';
import { showHandledErrors } from 'utils/SwalUtils';

export interface IAuthenticatedLayout {
  children: React.ReactNode;
  showFloatingChat?: boolean;
  showTopbar?: boolean;
  showSidebar?: boolean;
  mainWrapperRef?: LegacyRef<HTMLElement>;
}

export const AuthenticatedLayoutMain = ({
  children,
  mainWrapperRef,
}: IAuthenticatedLayout) => {
  const { state } = useSidebarContext();

  return (
    <main
      ref={mainWrapperRef}
      className={`main flex flex-col flex-grow  transition-all duration-150 ease-in overflow-y-auto max-h-full ${state.isMobile && state.isOpen ? 'hidden overflow-y-hidden' : ''
        }`}
    >
      {children}
    </main>
  );
};

const FloatingChatIcon = () => {
  const { attendanceState } = useAttendance();

  return (
    <NotificationBadge text={String(attendanceState.unreadMessages || '')}>
      <MessageIcon size={24} color="#fff" />
    </NotificationBadge>
  );
};

const FloatingChats = () => {
  const { state, setIsOpen } = useSidebarContext();
  const { state: authState } = useAuth();
  const { openChat, isOpen, closeChat } = useFloatingChat();
  const { loadChats, setSelectedChat } = useAttendance();
  const openSupportChat = () => {
    const dto: CreateChatDTO = {
      withAdmins: true,
    };

    if (authState.isTeacher) {
      dto.teacher = authState?.user?._id;
    }
    if (authState.isClient) {
      dto.client = authState?.user?._id;
    }
    createChat({
      ...dto,
    })
      .then(chat => {
        loadChats().then(() => {
          if (state.isMobile) {
            setIsOpen(false);
          }

          openChat();
          setSelectedChat(chat._id);
        });

        // router.push(`/app/chat/${chat._id}`);
      })
      .catch(err => {
        showHandledErrors(err, {
          showUnhandledModal: true,
        }).catch(() => {
          console.error(err);
        });
      });
  };

  if (isOpen) {
    return (
      <ChatInIProTuberDashboard
        onClose={() => {
          closeChat();
          setSelectedChat(null);
        }}
      />
    );
  }
  return (
    <div className="w-16 h-16 fixed bottom-10 right-10 flex items-center justify-center z-50">
      <div className="relative">
        <div
          onClick={() => {
            openChat();
          }}
          className="w-16 h-16 cursor-pointer bg-primaryColor rounded-full shadow-xl flex items-center justify-center z-50"
        >
          <FloatingChatIcon />
        </div>
        {authState && !authState.isAdmin && (
          <div
            onClick={() => {
              openSupportChat();
            }}
            className="w-7 h-7 cursor-pointer bg-primaryColor rounded-full shadow-xl absolute top-10 -right-7 flex items-center justify-center z-50"
          >
            <Tooltip text="Falar com suporte">
              <BiSupport color="white" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

const AuthenticatedLayout = ({
  children,
  showFloatingChat = true,
  showSidebar = true,
  showTopbar = true,
  mainWrapperRef,
}: IAuthenticatedLayout) => {
  const { setup } = useAnalytics({
    gtmId: GTM_ID,
  });
  const { state } = useAuth();
  const router = useRouter();
  const [accountStatus, setAccountStatus] = useState<
    AccountStatusResponse | undefined
  >(undefined);
  const [accountStatusLoaded, setAccountStatusLoaded] = useState(false);

  const stripeOnBoardingJourneyActive =
    state.isTeacher && !accountStatus?.canReceiveMoney;
  const allowedPagesOnStripeOnBoardingJourney = [
    '/app/p/referencias/[teacherProfileId]/editar',
    '/app/p/termos-stripe',
    '/app/p/referencias/criar',
    '/app/p/referencias',
    '/app/p',
  ];
  const isAllowedPage = allowedPagesOnStripeOnBoardingJourney.includes(
    router.pathname,
  );

  useEffect(() => {
    if (state.isTeacher && state?.user?.stripeId) {
      console.log('Fetch getAccountStatus on AuthCTX');
      getAccountStatus(state.user.stripeId as string)
        .then(setAccountStatus)
        .catch(err => {
          console.error(err);
        })
        .finally(() => setAccountStatusLoaded(true));
    }
  }, [state.isTeacher, state.user]);

  useEffect(() => {
    if (
      accountStatusLoaded &&
      stripeOnBoardingJourneyActive &&
      !isAllowedPage
    ) {
      router.push('/app/p');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStatusLoaded, router]);

  useEffect(() => {
    const { destroy, load } = setup();

    load()
      .then(() => {
        console.debug('==> useAnalytics loaded successfuly');
      })
      .catch(err => {
        console.warn('==> useAnalytics load error', err);
      });

    return () => {
      destroy();
    };
  });

  return (
    <AttendanceProvider>
      <SidebarContextProvider>
        <template
          id={TOUR_IDS.fullScreen}
          className="flex h-screen flex-row min-h-screen overflow-x-hidden max-h-screen overflow-y-hidden"
        >
          {showSidebar && (
            <Sidebar
              disableNavigation={stripeOnBoardingJourneyActive}
              disableNavigationWhiteList={allowedPagesOnStripeOnBoardingJourney}
            />
          )}
          <AuthenticatedLayoutMain
            showFloatingChat={false}
            mainWrapperRef={mainWrapperRef}
          >
            {showTopbar && (
              <Topbar
                userThumbnailUrl={
                  state?.user?.photoUrl || DEFAULT_THUMBNAIL_URL
                }
                userRole={state.user?.roles?.[0] || 'ROLE'}
                showSidebar={showSidebar}
              />
            )}

            <div className="flex flex-col w-full">
              {state.isAdmin || state.isClient
                ? children
                : !accountStatusLoaded ||
                  (stripeOnBoardingJourneyActive && !isAllowedPage)
                  ? null
                  : children}
            </div>

            {showFloatingChat && (
              <>
                <FloatingChats />
              </>
            )}
          </AuthenticatedLayoutMain>
        </template>
      </SidebarContextProvider>
    </AttendanceProvider>
  );
};

export default AuthenticatedLayout;

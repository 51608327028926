import React from 'react';
import { ChatMessage } from 'services/apiServices/IProTubeApi/schemas/ChatMessage';

// import { Container } from './styles';

export interface IChatTextMessageProps {
  message: ChatMessage;
  isSender: boolean;
  sentMessageBackgroundColor?: string;
  sentMessageColor?: string;
  receivedMessageBackgroundColor?: string;
  receivedMessageColor?: string;
}
const ChatTextMessage = ({
  message,
  sentMessageBackgroundColor = 'bg-blue-600',
  sentMessageColor = 'text-white',
  receivedMessageBackgroundColor = 'bg-gray-300',
  receivedMessageColor = 'text-gray-500',
  isSender,
}: IChatTextMessageProps) => {
  const sentMessageClassNames = `${sentMessageBackgroundColor} ${sentMessageColor}`;
  const receivedMessageClassNames = `${receivedMessageBackgroundColor} ${receivedMessageColor}`;

  return (
    <div>
      <span
        className={`px-4 py-2 rounded-lg inline-block ${
          isSender ? sentMessageClassNames : receivedMessageClassNames
        }`}
      >
        {message.src}
      </span>
    </div>
  );
};

export default ChatTextMessage;

import { useCallback } from 'react';

export type UseAnalyticsProps = {
  gtmId?: string | null;
};

export type AnalyticsIntegration = {
  element: HTMLElement;
  destroy: () => void;
};
const useAnalytics = ({ gtmId }: UseAnalyticsProps) => {
  const addGoogleTagManagerNoScript = useCallback(
    async (id: string): Promise<AnalyticsIntegration> => {
      const element = document.createElement('noscript');

      element.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display: none; visibility: hidden;" />`.trim();

      document.body.insertBefore(element, document.body.childNodes[0]);

      return { element, destroy: () => element.remove() };
    },
    [],
  );

  const addGoogleTagManagerScript = useCallback(
    async (id: string): Promise<AnalyticsIntegration> => {
      return new Promise(resolve => {
        const element = document.createElement('script');

        element.src = `https://www.googletagmanager.com/gtm.js?id=${id}&l=dataLayer`;
        element.async = false;

        (window as any).dataLayer = (window as any).dataLayer || [];

        (window as any).dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        element.onload = () => {
          console.debug('Google Tag Manager successfuly loaded');
          resolve({ element, destroy: () => element.remove() });
        };
        // element.innerHTML = `
        //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
        //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        //     })(window,document,'script','dataLayer','${id}');
        //     `;

        document.head.insertBefore(element, document.head.childNodes[0]);

        setTimeout(() => {
          console.warn(
            'Google Tag Manager was not loaded during acceptable time',
          );
          return { element, destroy: () => element.remove() };
        }, 1000);
      });
    },
    [],
  );

  const setup = useCallback(() => {
    const integrations: Promise<AnalyticsIntegration>[] = [];

    return {
      load: async () => {
        if (gtmId) {
          integrations.push(addGoogleTagManagerNoScript(gtmId));
          integrations.push(addGoogleTagManagerScript(gtmId));
        }
        return await Promise.all(integrations);
      },
      destroy: async () => {
        await Promise.all(integrations).then(loadedIntegrations => {
          loadedIntegrations.map(({ destroy }) => destroy());
        });
      },
    };
  }, [addGoogleTagManagerNoScript, addGoogleTagManagerScript, gtmId]);

  return {
    setup,
  };
};

export default useAnalytics;

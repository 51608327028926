import { SIDEBAR_LINKS } from 'config/sidebars';
import { useAuth } from 'contexts/Authentication';
import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useSidebarContext } from './SidebarContext';
import SidebarHeader from './SidebarHeader';
import SidebarItem from './SidebarItem';

import { useFloatingChat } from 'contexts/FloatingChat';
import { useAttendance } from 'contexts/Attendances';
import { createChat } from 'services/apiServices/IProTubeApi/chats';
import { CreateChatDTO } from 'services/apiServices/IProTubeApi/chats/types';

import { showHandledErrors } from 'utils/SwalUtils';
import { BiSupport } from 'react-icons/bi';
import { CloseIcon } from 'components/atoms/icons';
import { TOUR_IDS } from 'config/tour';

type SideBarProps = {
  disableNavigation?: boolean;
  disableNavigationWhiteList?: string[];
};

const Sidebar = ({
  disableNavigation = false,
  disableNavigationWhiteList = [],
}: SideBarProps) => {
  const { state, setIsOpen } = useSidebarContext();
  const { state: authState, getIsAuthorized } = useAuth();
  const { openChat } = useFloatingChat();
  const { loadChats, setSelectedChat } = useAttendance();
  const handleCreateChat = () => {
    const dto: CreateChatDTO = {
      withAdmins: true,
    };

    if (authState.isTeacher) {
      dto.teacher = authState?.user?._id;
    }
    if (authState.isClient) {
      dto.client = authState?.user?._id;
    }
    createChat({
      ...dto,
    })
      .then(chat => {
        loadChats().then(() => {
          if (state.isMobile) {
            setIsOpen(false);
          }

          openChat();
          setSelectedChat(chat._id);
        });

        // router.push(`/app/chat/${chat._id}`);
      })
      .catch(err => {
        showHandledErrors(err, {
          showUnhandledModal: true,
        }).catch(() => {
          console.error(err);
        });
      });
  };

  const router = useRouter();

  const items = SIDEBAR_LINKS.filter(o =>
    getIsAuthorized({
      acceptedRoles: o.acceptedRoles,
    }),
  );

  const [sideBarIconHref, setSideBarIconHref] = useState('/');

  useEffect(() => {
    if (authState.isClient) {
      setSideBarIconHref('/app/c');
      return;
    }
    if (authState.isTeacher) {
      setSideBarIconHref('/app/p');
      return;
    }
    if (authState.isAdmin) {
      setSideBarIconHref('/app/a');
      return;
    }
  }, [authState]);

  return (
    <aside
      className={`sidebar flex flex-col ${!state.isDesktop
          ? 'w-screen h-screen  fixed block top-0 left-0 z-50'
          : 'w-52'
        } md:shadow transform max-h-screen transition-transform duration-150 ease-in bg-secundaryColor ${!state.isOpen ? 'hidden' : ''
        }`}
    >
      <SidebarHeader
        applicationName="Menobel"
        applicationIconHref={sideBarIconHref}
        closeIcon={<CloseIcon className="text-white" size={24} />}
      />

      <div className="sidebar-content flex flex-col flex-grow w-full h-full px-4 py-6 justify-between content-between">
        <ul className="flex flex-col w-full">
          {items &&
            items.map((item, index) => {
              if (disableNavigationWhiteList.includes(item.href)) {
                return <SidebarItem key={`SidebarItem_${index}`} {...item} />;
              }

              return (
                <SidebarItem
                  key={`SidebarItem_${index}`}
                  {...item}
                  isDisabled={disableNavigation}
                />
              );
            })}
        </ul>
        <ul className="flex flex-col flex-grow w-full">
          {/* !authState?.isAdmin && (
            <SidebarItem
              id={TOUR_IDS.NAV.support}
              onClick={() => {
                handleCreateChat();
              }}
              text="Suporte"
              icon={<BiSupport color="white" />}
            />
            ) */}
          <SidebarItem
            id={TOUR_IDS.NAV.logout}
            onClick={() => {
              router.push('/login');
            }}
            text="Sair"
            icon={<FiLogOut color="white" />}
          />
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;

import useSize from '@react-hook/size';
import { Form } from '@unform/web';
import {
  CloseIcon,
  CreateVideoIcon,
  LoadingIcon,
  ReceiveMoneyIcon,
  ReturnIcon,
} from 'components/atoms/icons';
import UnformInput from 'components/atoms/unform/UnformInput';
import SimpleFileUploadContainer from 'components/Forms/Files/SimpleFileUploadContainer';
import { ADMINS_CHAT_ID, DEFAULT_THUMBNAIL_URL } from 'config/constants';
import {
  ALL_ALLOWED_FILE_MIMES,
  getHtmlAcceptFromMimeTypes,
} from 'config/files';
import { useAttendance } from 'contexts/Attendances';
import { useAuth } from 'contexts/Authentication';
import { useSocket } from 'contexts/Socket';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { createAttendance } from 'services/apiServices/IProTubeApi/attendances';
import { createServiceBudget } from 'services/apiServices/IProTubeApi/serviceBudgets';
import { uploadPublicFile } from 'services/apiServices/IProTubeApi/uploads';
import { getTypeFromMimeType } from 'services/socketServices/utils';
import { showHandledErrors, showMessageStatus } from 'utils/SwalUtils';
import Message from '../Chat/components/Message';
import CreateBudgetModal from '../Modals/CreateBudgetModal';
import LeftListChats from './components/LeftListChats';
import { getMessageProps } from './utils';

type ChatInIProTuberDashboardType = {
  onClose?: () => void;
};

const ChatInIProTuberDashboard = ({
  onClose,
}: ChatInIProTuberDashboardType) => {
  const [isSending, setIsSending] = useState(false);
  const endMessagesDivRef = useRef<HTMLDivElement>(null);
  const { state } = useAuth();
  const { attendanceState, setSelectedChat } = useAttendance();
  const { socketService } = useSocket();
  const [showRegisterBudgetModal, setShowRegisterBudgetModal] = useState(false);

  const [backChatList, setBackChatList] = useState<boolean | undefined>(
    undefined,
  );
  const chatSizeRef = useRef(null);

  const scrollToLastMessage = useCallback(() => {
    if (endMessagesDivRef.current)
      endMessagesDivRef.current.scrollIntoView({
        behavior: 'smooth',
      });
  }, [endMessagesDivRef]);

  const chatTopbar = useCallback(
    (extendsClassName = '') => (
      <header
        className={`h-10 py-2 max-h-10  bg-primaryColor w-full flex items-center justify-end px-2 ${extendsClassName}`}
      >
        <button onClick={onClose} className="flex items-center">
          <span className="text-white text-base font-medium px-2">Fechar</span>
          <CloseIcon size={24} color="#fff" />
        </button>
      </header>
    ),
    [],
  );

  const userId = state.user?._id;

  useEffect(() => {
    if (
      socketService &&
      attendanceState?.selectedChat &&
      attendanceState?.selectedChat.unreadMessages > 0
    ) {
      socketService.markChatAsRead({
        chatId: attendanceState?.selectedChat._id,
        senderId: state.user?._id as string,
        userId: state.user?._id as string,
        toId: attendanceState?.selectedChat?.user?._id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <CreateServiceModal
        chatId={state.user?._id as string}
        isOpen={showRegisterModal}
        chat={selectedChat as Chat}
        handleClose={() => setShowRegisterModal(false)}
        onDone={() => {
          //
        }}
      /> */}

      <CreateBudgetModal
        isOpen={showRegisterBudgetModal}
        handleClose={() => setShowRegisterBudgetModal(false)}
        onSubmit={data => {
          createServiceBudget({
            ...data,
            teacher: userId,
            client: attendanceState.selectedChat?.user?._id,
            chat: attendanceState.selectedChat._id,
            teacherProfile: attendanceState.selectedChat?.teacherProfile,
          })
            .then(serviceBudget => {
              showMessageStatus({
                imageType: 'success',
                titleContent: 'Orçamento criado!',
                textContent: `O seu cliente será notificado.`,
                customClass: 'font-poppins',
              });
              setShowRegisterBudgetModal(false);
            })
            .catch(err => {
              showHandledErrors(err).catch(() => {
                console.error(err);
              });
            });
        }}
      />
      <div className="fixed lg:bottom-0 lg:right-0 w-full h-full  lg:h-2/3 lg:w-2/3 xl:w-2/4 shadow-xl flex flex-col z-50">
        <div ref={chatSizeRef} className="w-full flex h-full min-h-full">
          <div
            className={`h-auto ${
              backChatList ? 'hidden' : ''
            }  w-full lg:w-1/2 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch bg-gray-100 lg:flex items-center justify-center flex-col`}
          >
            {chatTopbar('lg:hidden')}
            <LeftListChats
              onChatClick={setSelectedChat}
              onOpen={() => setBackChatList(true)}
              chats={attendanceState.chats}
              selectedChatId={attendanceState?.selectedChat?._id}
            />
          </div>

          {!attendanceState.selectedChat && (
            <div
              className={`hidden lg:flex flex-col h-full w-full justify-center items-center content-center bg-white`}
            >
              {chatTopbar()}
              <div className="flex h-full w-full justify-center items-center content-cente">
                <span>Selecione um contato para começar uma conversa</span>
              </div>
            </div>
          )}
          {!!attendanceState.selectedChat && (
            <div
              key={`ChatContent_${attendanceState?.selectedChat?._id}`}
              className={`flex flex-col h-full w-full ${
                backChatList ? '' : 'hidden'
              } lg:flex`}
            >
              {chatTopbar()}
              {!!attendanceState.selectedChat.user && (
                <div className="w-full h-20 bg-gray-200 flex p-2 items-start">
                  <div className="w-full flex items-center text-titleColor ">
                    <div>
                      <img
                        className="h-12 w-12 rounded-full object-fill"
                        src={
                          attendanceState.selectedChat?.user.photoUrl ||
                          DEFAULT_THUMBNAIL_URL
                        }
                      />
                    </div>
                    <div className="w-full mx-2">
                      <h1 className="font-semibold ">
                        {attendanceState.selectedChat?.user?.name || '-'}
                      </h1>
                      {/* <p className="text-base  font-medium opacity-80">online</p> */}
                    </div>
                  </div>
                </div>
              )}

              {state.isTeacher &&
                attendanceState.selectedChat.user?._id !== ADMINS_CHAT_ID && (
                  <div className="w-full flex bg-white items-center justify-between lg:justify-end p-4 border-b-2">
                    <button
                      onClick={() => setBackChatList(false)}
                      className="font-semibold lg:hidden text-sm"
                    >
                      <ReturnIcon size={20} />
                    </button>
                    <div className="flex flex-row space-x-4">
                      <button
                        onClick={() => {
                          showMessageStatus({
                            titleContent:
                              'Tem certeza que deseja criar um atendimento?',
                            imageType: 'question',
                            textContent: `O atendimento será criado e uma mensagem será enviada para o cliente.`,
                            textButton: 'Sim',
                            textCancelButton: 'Não',
                            onIsAccepted: () => {
                              createAttendance({
                                teacher: state.user?._id,
                                client: attendanceState.selectedChat.user?._id,
                                teacherProfile:
                                  attendanceState.selectedChat.teacherProfile,
                              })
                                .then(result => {
                                  showMessageStatus({
                                    imageSrc: '/images/win.svg',
                                    titleContent: 'Atendimento criado!',
                                    textContent: `O seu cliente será notificado.`,
                                    customClass: 'font-poppins',
                                  });
                                })
                                .catch(err =>
                                  showHandledErrors(err, {
                                    showUnhandledModal: true,
                                  }).catch(() => {
                                    console.error(err);
                                  }),
                                );
                            },
                          });
                        }}
                      >
                        <CreateVideoIcon size={24} color="#222" />
                      </button>
                      <button
                        onClick={() => {
                          setShowRegisterBudgetModal(true);
                        }}
                      >
                        <ReceiveMoneyIcon size={24} color="#222" />
                      </button>
                    </div>
                  </div>
                )}

              <div
                // style={{ maxHeight: height - 260 }}
                id="messages"
                className="flex h-full flex-col bg-gray-50 space-y-4 p-3 overflow-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch max-h-full"
              >
                {(attendanceState.selectedChat?.messages || []).map(
                  (message, index, messages) => {
                    const isLast = messages.length - 1 === index;

                    if (isLast) {
                      scrollToLastMessage();
                    }

                    const messageProps = getMessageProps(
                      message,
                      attendanceState.selectedChat,
                      userId,
                    );
                    return (
                      <Message
                        key={`Message_${index}`}
                        message={message}
                        {...messageProps}
                      />
                    );
                  },
                )}

                <div
                  ref={endMessagesDivRef}
                  className="invisible h-1 pb-6"
                ></div>
              </div>

              <Form
                className="flex w-full h-auto bg-gray-50"
                onSubmit={({ message }, { reset }) => {
                  if (message) {
                    if (socketService) {
                      setIsSending(true);
                      socketService.sendMessage({
                        userId,
                        chatId: attendanceState.selectedChat?._id as string,
                        toId: attendanceState.selectedChat.user?._id,
                        message: {
                          src: message,
                          type: 'text',
                          user: userId,
                          chat: attendanceState.selectedChat?._id,
                          senderThumbnailUrl: state.user?.photoUrl,
                          senderName:
                            state?.user &&
                            `${state?.user?.name} ${state?.user?.lastname}`,
                        },
                      });
                      setIsSending(false);
                      scrollToLastMessage();
                    }
                  }
                  reset();
                }}
              >
                <div className="flex flex-col w-full h-full border-t-2 border-gray-200 px-4 pt-4 my-2 ">
                  <div className="flex">
                    <div className="w-full">
                      <UnformInput
                        name="message"
                        type="text"
                        placeholder="Escreva uma mensagem"
                        className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 px-8 bg-gray-200 rounded-full py-3"
                      />
                    </div>
                    <div className="w-min inset-y-0 flex flex-row sm:flex justify-center items-center">
                      <SimpleFileUploadContainer
                        onFileUploaded={file => {
                          if (file) {
                            setIsSending(true);
                            uploadPublicFile({ file })
                              .then(uploadedFile => {
                                if (socketService)
                                  socketService.sendMessage({
                                    userId,
                                    chatId: attendanceState.selectedChat
                                      ?._id as string,
                                    toId:
                                      attendanceState.selectedChat.user?._id,
                                    message: {
                                      src: uploadedFile.url,
                                      type: getTypeFromMimeType(
                                        uploadedFile.mimetype,
                                      ),
                                      user: userId,
                                      chat: attendanceState.selectedChat?._id,
                                      senderThumbnailUrl: state.user?.photoUrl,
                                      senderName:
                                        state?.user &&
                                        `${state?.user?.name} ${state?.user?.lastname}`,
                                    },
                                  });

                                scrollToLastMessage();
                              })
                              .catch(err => {
                                console.error(`Erro in file upload`, err);
                              })
                              .finally(() => {
                                setIsSending(false);
                              });
                          }
                        }}
                        accept={getHtmlAcceptFromMimeTypes(
                          ALL_ALLOWED_FILE_MIMES,
                        )}
                      >
                        <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-6 w-6 text-gray-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            ></path>
                          </svg>
                        </button>
                      </SimpleFileUploadContainer>
                      <button
                        type="submit"
                        disabled={isSending}
                        className={`inline-flex  items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none ${
                          isSending ? 'bg-opacity-95' : ''
                        }`}
                      >
                        {!isSending && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-6 w-6 transform rotate-90"
                          >
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                          </svg>
                        )}

                        {isSending && <LoadingIcon />}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatInIProTuberDashboard;

import { TOUR_IDS } from 'config/tour';
import React from 'react';
import { useSidebarContext } from '../SidebarContext';

// import { Container } from './styles';

export interface ISidebarHeaderProps {
  applicationIcon?: React.ReactNode;
  applicationIconHref?: string;
  closeIcon: React.ReactNode;
  applicationName?: string;
}
const SidebarHeader = ({
  applicationIconHref,
  applicationName,
  applicationIcon,
  closeIcon,
}: ISidebarHeaderProps) => {
  const { state, setIsOpen } = useSidebarContext();

  const showCloseButton = !!(state.isMobile && state.isOpen && closeIcon);

  return (
    <div
      className={`sidebar-header flex items-center py-4 ${
        showCloseButton ? 'justify-around' : 'justify-center'
      }`}
    >
      <div className="inline-flex">
        <a
          href={applicationIconHref}
          className="inline-flex flex-row items-center"
        >
          {applicationIcon}
          {applicationName && (
            <span className="leading-10 text-gray-100 text-xl font-semibold ml-1 ">
              {applicationName}
            </span>
          )}
        </a>
      </div>
      {showCloseButton && (
        <div
          id={TOUR_IDS.NAV.close}
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          {closeIcon}
        </div>
      )}
    </div>
  );
};

export default SidebarHeader;

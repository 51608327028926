import AcceptTerms from 'components/molecules/AcceptTerms';
import CentralizedSpinnerLoader from 'components/molecules/loaders/CentralizedSpinnerLoader';
import { useAuth } from 'contexts/Authentication';
import { usePageLoading } from 'contexts/PageLoading';
import withAuth from 'hooks/withAuth';
import AuthenticatedLayout from 'layouts/AuthenticatedLayout';
import { useRouter } from 'next/dist/client/router';
import React, { useCallback, useEffect, useState } from 'react';
import { patchUser } from 'services/apiServices/IProTubeApi/users';

const RedirectUsers = () => {
  const { state, reloadUser } = useAuth();
  const { setIsLoading } = usePageLoading();
  const router = useRouter();

  const [needShowTerms, setNeedShowTerms] = useState(false);

  const redirectToUserHome = useCallback(() => {
    if (state.isAdmin) {
      setIsLoading(false);
      router.push('/app/a');
    }
    if (state.isTeacher) {
      setIsLoading(false);
      router.push('/app/p');
    }
    if (state.isClient) {
      setIsLoading(false);
      router.push('/app/c');
    }
  }, [router, setIsLoading, state.isAdmin, state.isClient, state.isTeacher]);
  useEffect(() => {
    setIsLoading(true);
    if (state) {
      if (!state.user?.acceptedTerms) {
        setIsLoading(false);
        return setNeedShowTerms(true);
      }
      redirectToUserHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onAcceptTerms = async (): Promise<void> => {
    await patchUser(state.user?._id, {
      acceptedTerms: true,
    }).then(() => {
      reloadUser().catch(() => {
        //
      });
      redirectToUserHome();
    });
  };
  return (
    <AuthenticatedLayout showFloatingChat={false} showSidebar={false}>
      <div className="">
        {needShowTerms ? (
          <AcceptTerms onAccept={onAcceptTerms} />
        ) : (
          <CentralizedSpinnerLoader />
        )}
      </div>
    </AuthenticatedLayout>
  );
};

const App = () => {
  return withAuth(<RedirectUsers />, []);
};

export default App;

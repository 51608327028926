import {
  AttendancesTeacherStatisticsResponse,
  CreateAttendanceDTO,
  FinishAttendanceDTO,
  GetAttendanceOptions,
  GetAttendancesOptions,
  UpdateAttendanceDTO,
} from './types';
import api from '../iProTubeApi';
import { Attendance } from '../schemas/Attendance.schema';
import { IUploadFile, IUploadedFile } from './types';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';

export const uploadFile = async ({
  file,
}: IUploadFile): Promise<IUploadedFile> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = new FormData();
  formData.append('file', file);

  return await api
    .post('/attendances/files', formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then(({ data }) => data);
};

const endpoint = '/attendances';

export async function createAttendance(
  dto: CreateAttendanceDTO,
): Promise<Attendance> {
  return await api.post(endpoint, dto).then(({ data }) => data);
}

export async function updateAttendance(
  dto: UpdateAttendanceDTO,
): Promise<Attendance> {
  return await api.put(`${endpoint}/`, dto).then(({ data }) => data);
}
export async function patchAttendance(
  id: string,
  dto: Partial<UpdateAttendanceDTO>,
): Promise<Attendance> {
  return await api.patch(`${endpoint}/${id}`, dto).then(({ data }) => data);
}

export async function deleteAttendance(id: string): Promise<void> {
  return await api.delete(`${endpoint}/${id}`);
}

export async function getAttendances(
  options?: GetAttendancesOptions,
): Promise<PaginatedResponse<Attendance>> {
  return await api
    .get(`${endpoint}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getAttendance(
  id: string,
  options?: GetAttendanceOptions,
): Promise<Attendance> {
  return await api
    .get(`${endpoint}/${id}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function startAttendance(id: string): Promise<Attendance> {
  return await api.put(`${endpoint}/${id}/start`).then(({ data }) => data);
}

export async function pauseAttendance(id: string): Promise<Attendance> {
  return await api.put(`${endpoint}/${id}/pause`).then(({ data }) => data);
}

export async function resumeAttendance(id: string): Promise<Attendance> {
  return await api.put(`${endpoint}/${id}/resume`).then(({ data }) => data);
}

export async function finishAttendance(
  id: string,
  dto?: FinishAttendanceDTO,
): Promise<Attendance> {
  return await api
    .put(`${endpoint}/${id}/finish`, dto)
    .then(({ data }) => data);
}

export async function getTeacherAttendanceStatistics(
  userId: string,
): Promise<AttendancesTeacherStatisticsResponse> {
  return await api
    .get(`${endpoint}/statistics/teacher/${userId}`)
    .then(({ data }) => data);
}

import { MoneyIcon } from 'components/atoms/icons';
import Tooltip from 'components/atoms/Tooltip';
import React from 'react';
import {
  getPaymentStatusLabel,
  paymentStatus,
} from 'services/apiServices/IProTubeApi/schemas/Payment.schema';

export type CardPaymentStatusProps = {
  status: typeof paymentStatus[number];
};
const CardPaymentStatus = ({ status }: CardPaymentStatusProps) => {
  let colorsClassName = '';

  if (status === 'PAID') {
    colorsClassName = 'border-green-500 text-green-500';
  }

  if (status === 'ERROR') {
    colorsClassName = 'border-red-500 text-red-500';
  }

  if (status === 'PENDING') {
    colorsClassName = 'border-yellow-500 text-yellow-500';
  }

  return (
    <Tooltip
      text="Status do pagamento"
      className={`flex items-center space-x-1 font-poppins bg-transparent  border-2 p-2 text-xs rounded-md ${colorsClassName}`}
    >
      <MoneyIcon />
      <span>{getPaymentStatusLabel(status)}</span>
    </Tooltip>
  );
};

export default CardPaymentStatus;

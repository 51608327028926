import Modal from 'components/atoms/Modal';
import { InputTextGroup } from 'components/Forms/InputGroup';
import { TextAreaInputGroup } from 'components/Forms/InputGroup/TextAreaInputGroup';
import React from 'react';
import { CreateServiceBudgetDTO } from 'services/apiServices/IProTubeApi/serviceBudgets/types';

export type SubmitData = Pick<
  CreateServiceBudgetDTO,
  'title' | 'description' | 'amount'
>;
interface CreateServiceProps {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (data: SubmitData) => void;
}

const CreateBudgetModal = ({
  handleClose,
  isOpen,
  onSubmit,
}: CreateServiceProps) => {
  const handleSubmit = (data: SubmitData): void => {
    onSubmit({
      ...data,
      amount: Number(data.amount * 100),
    });
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        isGif={true}
        title="Criar orçamento"
        action="Criar"
        onSubmit={handleSubmit}
      >
        <InputTextGroup
          name="title"
          type="text"
          extendsInputClassName="mt-4 p-4"
          placeholder="Dê um nome para este orçamento"
          required={true}
        />
        <TextAreaInputGroup
          name="description"
          extendsClassName="mt-4"
          placeholder="Escreva uma descrição para esse orçamento"
          required={true}
        />
        <InputTextGroup
          name="amount"
          type="number"
          step="0.01"
          extendsInputClassName="mt-4 p-4"
          placeholder="Quanto vai custar ?"
          required={true}
        />
      </Modal>
    </>
  );
};

export default CreateBudgetModal;

import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

interface Props {
  name: string;
}
export type InputProps = Props & JSX.IntrinsicElements['input'];

const UnformInput = ({ name, ...rest }: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue = '', registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <input
      id={fieldName}
      name={name}
      ref={inputRef}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

export default UnformInput;

import api from '../iProTubeApi';

export interface UploadProgress {
  filename: string;
  progress: number;
}
export interface IUploadFile {
  file: File;
  onChangeProgress?: (progress: UploadProgress) => void;
}
export interface IUploadedFile {
  url: string;
  mimetype: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProgressFromEvent = (e: any) => {
  const progress = Number(Math.round((e.loaded * 100) / e.total));

  return progress > 2 ? progress / 2 : 0;
};

export const uploadPublicFile = async ({
  file,
  onChangeProgress,
}: IUploadFile): Promise<IUploadedFile> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = new FormData();
  formData.append('file', file);

  return await api
    .post('/uploads/public', formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      onUploadProgress: e => {
        if (onChangeProgress) {
          const progress = getProgressFromEvent(e);
          onChangeProgress({
            filename: file.name,
            progress: progress,
          });
        }
      },
    })
    .then(({ data }) => {
      if (onChangeProgress)
        onChangeProgress({
          filename: file.name,
          progress: 100,
        });

      return data;
    });
};

export const uploadPrivateFile = async ({
  file,
  onChangeProgress,
}: IUploadFile): Promise<IUploadedFile> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = new FormData();
  formData.append('file', file);

  return await api
    .post('/uploads/private', formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      onUploadProgress: e => {
        if (onChangeProgress) {
          onChangeProgress({
            filename: file.name,
            progress: getProgressFromEvent(e),
          });
        }
      },
    })
    .then(({ data }) => {
      if (onChangeProgress)
        onChangeProgress({
          filename: file.name,
          progress: 100,
        });

      return data;
    });
};

import React from 'react';

// import { Container } from './styles';

export type EmptyResultProps = {
  text?: string;
};
const EmptyResult = ({
  text = 'Parece que não há resultados aqui.',
}: EmptyResultProps) => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center text-center">
      <img src="/images/empty-result.svg" className="h-28 w-28" />
      <span className="font-light">{text}</span>
    </div>
  );
};

export default EmptyResult;

import api from '../iProTubeApi';
import { Chat, PreparedChat } from '../schemas/Chat';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';
import {
  CreateChatDTO,
  UpdateChatDTO,
  GetChatOptions,
  GetChatsOptions,
} from './types';

const endpoint = '/chats';

export async function createChat(dto: CreateChatDTO): Promise<Chat> {
  return await api.post(`${endpoint}`, dto).then(({ data }) => data);
}

export async function updateChat(
  id: string,
  dto: UpdateChatDTO,
): Promise<Chat> {
  return await api.put(`${endpoint}/${id}`, dto).then(({ data }) => data);
}
export async function patchChat(
  id: string,
  dto: Partial<UpdateChatDTO>,
): Promise<Chat> {
  return await api.patch(`${endpoint}/${id}`, dto).then(({ data }) => data);
}

export async function deleteChat(id: string): Promise<void> {
  return await api.delete(`${endpoint}/${id}`);
}

export async function getChats(
  options?: GetChatsOptions,
): Promise<PaginatedResponse<Chat>> {
  return await api
    .get(`${endpoint}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getChat(
  id: string,
  options?: GetChatOptions,
): Promise<Chat> {
  return await api
    .get(`${endpoint}/${id}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getUserChats(
  options?: GetChatsOptions,
): Promise<PreparedChat[]> {
  return await api
    .get(`${endpoint}/q/prepared`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

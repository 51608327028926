import {
  UnformTextArea,
  UnformTextAreaProps,
} from 'components/Forms/Unform/UnformTextArea';
import React, { useState } from 'react';

export type TextAreaInputGroupProps = {
  name: string;
  label?: string;
  extendsClassName?: string;
  placeholder?: string;

  textAreaExtendsClassName?: string;
  required?: boolean;
  showLimits?: boolean;
} & UnformTextAreaProps;

export const TextAreaInputGroup = ({
  name,
  label,
  extendsClassName = '',
  textAreaExtendsClassName = '',
  required,
  onChange,
  showLimits = true,
  defaultValue,
  maxLength,
  minLength,
  ...rest
}: TextAreaInputGroupProps) => {
  const [digits, setDigits] = useState((defaultValue as string)?.length || 0);
  return (
    <div className={`flex flex-col items-start w-full ${extendsClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className="mb-1 text-xs font-normal tracking-wide focus-within:text-primaryColor-200 font-poppins"
        >
          {label}
        </label>
      )}

      <UnformTextArea
        name={name}
        className={`p-4 outline-none shadow w-full border border-primaryColor rounded focus:outline-none focus:ring-1 ring-primaryColor ${textAreaExtendsClassName} `}
        style={{
          minHeight: '10rem',
        }}
        required={required}
        onChange={e => {
          if (onChange) onChange(e);

          if (showLimits && maxLength) {
            setDigits(e.target.value?.length || 0);
          }
        }}
        defaultValue={defaultValue}
        maxLength={maxLength}
        minLength={minLength}
        {...rest}
      />
      {showLimits && (
        <>
          {maxLength && (
            <div className="flex w-full justify-end items-center text-gray-600">
              {!!minLength && digits < minLength ? (
                <span>Faltam {minLength - digits} caracteres.</span>
              ) : (
                <>
                  <span>
                    {digits}/{maxLength}
                  </span>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

import api from '../iProTubeApi';
import { Notification } from '../schemas/Notification.schema';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';
import { GetNotificationOptions, GetNotificationsOptions } from './types';

const endpoint = '/notifications';

// export async function createNotification(dto: CreateNotificationDTO): Promise<Notification> {
//   return await api.post(`${endpoint}`, dto).then(({ data }) => data);
// }

// export async function updateNotification(
//   id: string,
//   dto: UpdateNotificationDTO,
// ): Promise<Notification> {
//   return await api.put(`${endpoint}/${id}`, dto).then(({ data }) => data);
// }
// export async function patchNotification(
//   id: string,
//   dto: Partial<UpdateNotificationDTO>,
// ): Promise<Notification> {
//   return await api.patch(`${endpoint}/${id}`, dto).then(({ data }) => data);
// }

// export async function deleteNotification(id: string): Promise<void> {
//   return await api.delete(`${endpoint}/${id}`);
// }

export async function getNotifications(
  options?: GetNotificationsOptions,
): Promise<PaginatedResponse<Notification>> {
  return await api
    .get(`${endpoint}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getNotification(
  id: string,
  options?: GetNotificationOptions,
): Promise<Notification> {
  return await api
    .get(`${endpoint}/${id}`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getUserNotifications(
  options?: GetNotificationsOptions,
): Promise<PaginatedResponse<Notification>> {
  return await api
    .get(`${endpoint}/_/me`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function maskUserNotificationsAsSeen(): Promise<Notification[]> {
  return await api.patch(`${endpoint}/_/seen`).then(({ data }) => data);
}

interface Props {
  buttonText: string;
}

const FormButtonSubmit = ({ buttonText }: Props) => {
  return (
    <button className="p-3 w-1/3 bg-green-500 text-white text-roboto font-medium rounded-lg text-base">
      {buttonText}
    </button>
  );
};

export default FormButtonSubmit;
